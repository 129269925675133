import React, { useState, useContext } from "react";
import { View , Platform } from 'react-native' 
import Login from './Login'
import SignUp from './SignUp'
import { SuccessSignUp } from './User'
import { UserContext } from '../../../utils/context'
import ForgotPwd from "./ForgotPwd";

export default function UserContainer(props) {

    const what = Platform.OS === 'web' ? props.params.what : props.route.params.what
    const [ who, setWho] = useState(what || "login"); 
    const [ userSuccess, setUserSuccess] = useState(null); 
    const [ typeSuccess, setTypeSuccess] = useState(null); 
    const { getUserApi } = useContext(UserContext);

    const main = {
        "login" : {
            "component": <Login {...props} 
            onSuccess={(user) => {
                setUserSuccess(user)
                setWho('successLogin')
                setTypeSuccess("LOGIN")
            }}
            onPwdClick={() => setWho('forgotpwd')}/>
        },
        "forgotpwd": {
            "component": <ForgotPwd />
        },
        "successLogin": {
            "component": <SuccessSignUp 
                user={userSuccess} 
                type={typeSuccess} 
                onOk={() => {
                    getUserApi()
                    props.navigation ? props.navigation.pop() : props.onOk()
                }}/>
        },
        "signup": {
            "component": <SignUp {...props} 
                onSuccess={(user) => {
                    setWho('successLogin')
                    setUserSuccess(user.user)
                    setTypeSuccess("SIGNUP")
                }}/>
        }
    }

    
    return (
        <View style={{ marginHorizontal: 0 }}>
            { main[who].component}
        </View>
    )
}

