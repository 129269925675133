import React from 'react';
import { Text, View } from 'react-native';
//import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap' 
//import { DARK_TAB } from '../style'
//import logo from '../images/dcm_white_navigation.png'
//import { UserContext } from '../utils/context'
import "./header.css"


/*export default function HeaderBlank(props) {

    const { code, languages } = useContext(UserContext);
    
    const classNameChoice = (current, navName) => navName === current ? "focus active" : "focus"
    return (
        <Navbar  style={{backgroundColor: DARK_TAB}} collapseOnSelect sticky="top" expand="lg" variant="dark">
            <Container>
                <Navbar.Brand onClick={() => props.onChangeMain('home')}>
                    <img alt="DCM" src={logo} height="30px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}*/

export default function HeaderBlank(props) {
    //const { code, languages } = useContext(UserContext);
    //const classNameChoice = (current, navName) => navName === current ? "focus active" : "focus"
    return (
        <View><Text>header blank</Text></View>
    )

}