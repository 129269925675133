import React, { useContext } from "react";
import { View, Platform, FlatList } from 'react-native'
import { HeaderTable, RowTable } from '../../Utils/Kit/ShowTableExample'
import { getUnity, DISTANCE, converValue } from "../../../metrics";
import { UserContext } from '../../../utils/context'

export default function RegulatorVRI(p){
    const data = Platform.OS === 'web' ? p.data : p.route.params.data
    const { metrics } = useContext(UserContext);
    const merge_data = data
        .map( el => { return {'scala_positon': el.fallPointPos, 'meters': converValue(el.meters, metrics, DISTANCE )}})
        .filter( el => el.scala_positon !== "")
    return (
        <View style={{padding: 12}}>
            <HeaderTable 
                scala_positon={"Scala position"} 
                meters={getUnity(metrics, DISTANCE)} />
            <FlatList keyExtractor={item => item.scala_positon}
                renderItem={(item,index) => <RowTable key={index} item={item}/>}
                data={merge_data}  />
        </View>
    )
}