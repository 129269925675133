import React, { useContext } from 'react';

import { DARK_TAB, MEDIUM_W, WHITE } from '../style'
import logo from '../images/dcm_white_navigation.png'
import { Text, View, useWindowDimensions, TouchableOpacity } from 'react-native';
import { CustomIcon } from './General/CustomIcon';
import { UserContext } from '../utils/context'

import "./header.css"

export default function HeaderBootstrap(props) {
    const { code, languages } = useContext(UserContext);
    const classNameChoice = (current, navName) => navName === current ? true : false

    /*function getPrefersReducedMotion() {
        const QUERY = '(prefers-reduced-motion: no-preference)';
        const mediaQueryList = window.matchMedia(QUERY);
        const prefersReducedMotion = !mediaQueryList.matches;
        return prefersReducedMotion;
      }*/

    const styleForKit = classNameChoice(props.main, "kit") ? { borderColor: WHITE, borderBottomWidth: 1} : null

    const { width } = useWindowDimensions();

    
    return (
        <View style={{width: width, 
            backgroundColor: DARK_TAB, 
            flexDirection: 'row', 
            paddingVertical: 10, 
            paddingHorizontal: 20}}>
            <TouchableOpacity style={{flex: 1, alignContent: 'center'}} 
                onPress={() => props.onChangeMain('home')}>
                <img alt="DCM" src={logo} height="30px" width='150px' /> 
            </TouchableOpacity>
            <TouchableOpacity style={{
                marginHorizontal: 30, 
                flex: 1, 
                alignItems: 'center', 
                alignSelf: 'center'}} 
                onPress={() => props.onChangeMain('kit')}>
                <Text style={{...MEDIUM_W, ...styleForKit}}>
                    {languages["kit_title"][code]}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ 
                flex: 1, alignSelf: 'center', alignItems: 'end'}} onPress={() => props.onChangeMain('settings')}>
                <CustomIcon name={'settings'} color={WHITE} size={32}/>
            </TouchableOpacity>
        </View>
    )
}


/*export default function HeaderBootstrap(props) {

    const { code, languages } = useContext(UserContext);
    
    const classNameChoice = (current, navName) => navName === current ? "focus active" : "focus"

    function getPrefersReducedMotion() {
        const QUERY = '(prefers-reduced-motion: no-preference)';
        const mediaQueryList = window.matchMedia(QUERY);
        const prefersReducedMotion = !mediaQueryList.matches;
        return prefersReducedMotion;
      }
    return (
        <Navbar  style={{backgroundColor: DARK_TAB}} 
            collapseOnSelect sticky="top" expand="lg" variant="dark">
            <Container>
                <Navbar.Brand onClick={() => props.onChangeMain('home')}>
                    <img alt="DCM" src={logo} height="30px" /> 
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto" >
                        <Nav.Link style={classNameChoice(props.main, "home")}
                            onClick={() => props.onChangeMain('home')}>Home
                        </Nav.Link>
                        <Nav.Link className={classNameChoice(props.main, "settings")}
                            onClick={() => props.onChangeMain('kit')}>
                            {languages["kit_title"][code]}
                        </Nav.Link>
                        <NavDropdown 
                            
                            title={languages["utility_title"][code]} id="collasible-nav-dropdown">
                            <NavDropdown.Item  className={classNameChoice(props.main, "kit")}
                            onClick={() => props.onChangeMain('kit')}>
                                {languages["kit_title"][code]}
                            </NavDropdown.Item>
                            <NavDropdown.Item className={classNameChoice(props.main, "factor")} onClick={() => props.onChangeMain('factor')}>
                                {languages["factor_flow_title"][code]}
                            </NavDropdown.Item> 
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item className={classNameChoice(props.main, "video")} onClick={() => props.onChangeMain('video')}>
                                {languages["video_nav"][code]}
                            </NavDropdown.Item> 
                            
                        </NavDropdown> 
                    </Nav>
                    <Nav>
                        <Nav.Link className={classNameChoice(props.main, "settings")}
                            onClick={() => props.onChangeMain('settings')}>
                            {languages["settings"][code]}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )*/