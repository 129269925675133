import React, { useContext }  from "react";
import { View, Text } from 'react-native' 
import { CONTAINER_PAD, LIGHT_GREEN, getFont, DARK_GRAY, BUTTON_PRIMARY, BUTTON_FLAT_LINK, DEFAULT_FONT_LIGHT, DEFAULT_FONT_BOLD, DEFAULT_FONT } from '../../../style'
import { TextButton } from "../../General/Other";
import { UserContext } from '../../../utils/context'

export default function User(props) {

    const u = props.user
    const sigla = (u && u.name) ? u.name.substring(0,1) + u.last_name.substring(0,1) : "AA"
    
    return (
        <View style={{paddingHorizontal: CONTAINER_PAD, alignItems: 'center', marginVertical: 20}}>
            <View style={{height: 100, width: 100, 
                    backgroundColor: LIGHT_GREEN,
                    borderRadius: 100/2, justifyContent: 'center', marginBottom: 12}}>
                <Text style={{...getFont(40, DARK_GRAY, DEFAULT_FONT_BOLD), textAlign:'center'}}>
                    {sigla.toUpperCase()}
                </Text>
            </View>
            { u && <Text style={getFont(20, DARK_GRAY, DEFAULT_FONT_BOLD)}>{u.name + " " + u.last_name}</Text> }
            { u && <Text style={getFont(16, DARK_GRAY, DEFAULT_FONT_LIGHT)}>{u.email}</Text> }
        </View>
    )
}


export function NotUserLogged(props) {

    const { languages, code } = useContext(UserContext);

    
    return (
        <View style={{paddingHorizontal: CONTAINER_PAD, justifyContent: 'center', marginVertical: 20}}>
            <View style={{backgroundColor: LIGHT_GREEN,
                    borderRadius: 8, marginBottom: 12, padding: 20}}>
                <Text style={{...getFont(26, DARK_GRAY, DEFAULT_FONT_BOLD)}}>
                    {languages['not_access_performed'][code]}
                </Text>
                <View style={{alignItems:'flex-start'}}>
                    <TextButton type={BUTTON_FLAT_LINK} extra_style={{width:'auto'}}
                        title={languages['register_now'][code]} 
                        onPress={() => props.onLoginNow("signup")}/>
                </View>
                
                <TextButton type={BUTTON_PRIMARY} 
                    title={languages['access_now'][code]} 
                    onPress={() => props.onLoginNow("login")}/>
                
            </View>
        </View>
    )
}




export function SuccessSignUp(props) {
    
    const u = props.user
    
    const t = props.type
    const { languages, code } = useContext(UserContext);

    return (
        <View style={{paddingHorizontal: CONTAINER_PAD, justifyContent: 'center', marginVertical: 20}}>
            <View style={{backgroundColor: LIGHT_GREEN,
                    borderRadius: 8, marginBottom: 12, padding: 20}}>
                <Text style={getFont(22, DARK_GRAY, DEFAULT_FONT)}> 
                    { t === 'LOGIN' ? languages["welcome_back"][code] : languages["registration_success"][code]} 
                </Text>
                <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), 
                    {marginBottom: 22}]}>{u ? u.name : ''}</Text>            
                <TextButton type={BUTTON_PRIMARY}
                    title={"OK"} 
                    onPress={() => props.onOk()}/>
            </View>
        </View>
    )
}