import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, ScrollView, useWindowDimensions, Image, Platform } from 'react-native'
import { MEDIUM_B, getFont, DARK_GRAY, TEXT_B, GREEN, CLEAR, DEFAULT_FONT } from "../../../style";
import { formatNumber } from '../../../utils'
import { UserContext } from '../../../utils/context'
import { CustomTextInput } from '../../General/Other'
import { DISTANCE, getUnity } from '../../../metrics'


export default function ChooseWidth(props) {

    const appcontext = useContext(UserContext);
    const code = appcontext.code
    const { width } = useWindowDimensions();

    const [textW, setTextW] = useState(props.widthWork || '')
    var textInputWidth = useRef(null)

    useEffect(() => {
        props.isValid(props.widthWork && props.widthWork > 0)
    })



    const box = [
        { label: "B", color: "red", text: 0 },
        { label: "C", color: "green", text: textW/6 },
        { label: "D", color: "blue", text: textW/3 },
        { label: "E", color: "yellow", text: textW/2 }
    ]

    const widthBox = Platform.OS === 'web' ? ((width*0.5)/4)*0.8 : width*0.18

    const renderBox = (p, k) => {
        return (
            <View style={{alignItems: 'center'}} key={k}>
                <View style={{width: 
                    widthBox*0.6, 
                    height: widthBox*0.6,
                    alignItems: 'center', 
                    borderColor: GREEN, borderLeftWidth: k === 0 ? 1 : 0, borderRightWidth: k === 0 ? 1 : 0}}>
                    { k === 0 && <Image style={{width:widthBox*0.4, height: widthBox*0.4}} 
                       source={require('../../../images/tractor.png')}/> }
                </View>
                <View key={k+""} 
                    style={{alignItems: 'center', 
                        borderColor: GREEN, borderWidth: 1, borderRadius: 8,
                        justifyContent: 'center', padding: 16, 
                        width: widthBox, height: widthBox,
                        marginHorizontal: 10, backgroundColor: CLEAR}}>
                        <Text style={{...TEXT_B, marginBottom: 3}}>{formatNumber(p.text)}</Text>
                </View>
                <Text style={getFont(16,DARK_GRAY)}>
                    {p.label}
                </Text>
            </View>
        )
    }

    

    return (
        <ScrollView style={{paddingHorizontal: 15}}>
            <Text style={{...getFont(16, DARK_GRAY, DEFAULT_FONT), marginBottom: 20}}>{appcontext.languages["set_ww"][code]}</Text>
            <Text style={TEXT_B}>{appcontext.languages["width"][code] + ' (' + getUnity(appcontext.metrics, DISTANCE) + ')'}</Text>
            <CustomTextInput 
                ref={(ref) => textInputWidth.current = ref} 
                    accept="int"
                    keyboardType="numbers-and-punctuation" 
                    returnKeyType='done'
                    style={{padding: 5, ...MEDIUM_B}} 
                    onChangeText={(t) => {
                        setTextW(t)
                        props.isValid(true)
                        props.onWidthChange(t)
                    }} 
                    text={String(textW)}
                    placeholder={getUnity(props.metrics, DISTANCE)}/>
            <View style={{flexDirection: 'row', 
                alignItems:'center', 
                justifyContent:'center', 
                paddingVertical: 20}}>
                { box.map( (b, key) => renderBox(b, key))}
            </View>
            <Text style={{...getFont(16, DARK_GRAY), marginBottom: 10}}>
                {appcontext.languages["explain_update_box"][code]}
            </Text>
        </ScrollView>
    )
}