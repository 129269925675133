import React from "react";
import { View, Image } from 'react-native'
import { DARK_GRAY, LIGHT_GRAY } from '../../style'
import { CustomIcon } from "./CustomIcon";


export default function ProgressiveImage(props) {

    
    return (
        <View style={{backgroundColor: LIGHT_GRAY, borderRadius: 8}}>
            <View style={{alignItems: 'center', position: 'absolute', top: 0, right: 0, justifyContent: 'center',
                width: props.width, height: props.height}}>
                <CustomIcon name={'cloud-offline'} size={props.width*0.2} color={DARK_GRAY} />
            </View>
            <Image source={{'uri':props.img_url}} 
                style={props.style_image} />
        </View>
    )

}

