import React, { useContext } from "react";
import { View, Platform } from 'react-native'
import { BUTTON_PRIMARY, BUTTON_SECONDARY } from '../../style'
import { TextButton } from "../General/Other";
import { UserContext } from '../../utils/context'
import Dot from './Dot'
import "./paginator.css"


export default function Paginator(props) {
    const { languages, code } = useContext(UserContext);
    return (
        <div className="footer">
            <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 6, marginTop: 8}}>
            { [...Array(props.numberPage).keys()].map( el => <Dot key={el} isActive={el===props.pageActive}/>) }
            </View>
            <View style={{justifyContent: 'center', alignItems: 'center', 
                paddingHorizontal: Platform.OS === 'web' ? '10%' : 15, marginBottom: 8}}>
                { props.pageActive < props.numberPage-1 &&  <TextButton extra_style={{width: "100%"}} disabled={!props.isEnableNext}
                    title={languages["next"][code].toUpperCase()}
                    onPress={() => props.onNext(props.pageActive)} 
                    type={props.isEnableNext ? BUTTON_PRIMARY : BUTTON_SECONDARY}/> }
            </View>
        </div>
    )
}