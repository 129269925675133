import React, { useState, useContext }  from "react";
import { Text, TouchableOpacity, Platform, View, Image } from 'react-native';
import { TEXT_B, TITLE_B, container, getFont, 
    BUTTON_SQUARE_ACTIVE, WHITE, LIGHT_GRAY, LIGHT_GREEN, DEFAULT_FONT } from "../../../style";
import { CustomModal } from '../../General/Other'
import ProgressiveImage from '../../General/ProgressiveImage'
import InfoFertilizer from './InfoFertilizer'
import { UserContext } from '../../../utils/context'
import { distributionIsAvailable } from '../../../utils'
import CountryFlag from "react-native-country-flag";
import { CustomIcon } from "../../General/CustomIcon";
//import Icon from 'react-native-ionicons';
import _ from 'underscore'


export function StarRating(props) {


    const start_number = _.range(0,5,1);
    //0,1,2,3,4
    const get_star_type = (v, index) => {
        const mappingValue = v*5
        return mappingValue-1 >= index ? require('../../../images/pieno_DCM_recensione.png') : 
            (mappingValue-1 >= index-0.5 ? require('../../../images/mezzo_DCM_recensione.png'): require('../../../images/trasparente_DCM_recensione.png') )
    }

    const s = props.similarity_score && props.similarity_score !== 0 ? true : false

    return (
        <View style={{flexDirection: 'row'}}>
            { s && start_number.map( (s) => <Image key={s}
                style={{'width':20,'height':20, marginHorizontal: 2, resizeMode: 'contain'}} 
                source={get_star_type(props.similarity_score, s)} /> ) }
        </View>
    )
}

export function FlagView(props) {
    
    

    return (
        <View style={{flexDirection: 'row', marginTop: 5}}> 
            {props.tags.map( (t, key) => 
                <CountryFlag key={key}
                    isoCode={t} 
                    size={20} 
                    style={{width: 28, height: 20, borderRadius: 3, marginHorizontal: 2}}/>
            )}
        </View>
    )
}

export default function FertilizerRow(props){

    const { languages, code } = useContext(UserContext);
    const [modalVisible, setModalVisible] = useState(false);
    const bgColor = props.item.id === props.selectedId ? LIGHT_GREEN : Platform.OS === 'web' ? LIGHT_GRAY : WHITE

    //const unitDistribution = ['< 2.0 mm', '2.0 - 3.3', '3.3 - 4.75', '> 4.75']
    
    return (
        <TouchableOpacity style={{padding: 16, backgroundColor: bgColor, borderRadius: 8, marginVertical: 8}} 
            onPress={() => props.onPushRow(props.item)}>
            { Platform.OS === 'web' && <CustomModal 
                titleModal={'Title'}
                innerComponet={<InfoFertilizer fertilizer={props.item} />}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/> }
            <View  style={{flexDirection: 'row'}}>
                <View>
                    <ProgressiveImage width={120} height={120}
                        img_url={props.item.img_url} 
                        style_image={[
                            props.item.id === props.selectedId ? container.underline_box : container.not_underline_box,
                            {width: 120, height: 120}
                        ]}/>
                    <TouchableOpacity 
                        onPress={() => { 
                            props.onPushInfo(props.item)
                            Platform.OS === 'web' && setModalVisible(true)}
                        } 
                        style={[BUTTON_SQUARE_ACTIVE, {position: 'absolute', top: 10, right: 10}]}>
                        <CustomIcon name={'information'} size={15} color={WHITE} />
                    </TouchableOpacity>
                </View>
                
                <View style={{paddingHorizontal: 10, flex: 1}}>
                    <Text style={TITLE_B}>
                        {props.item.name}
                    </Text>
                    <Text style={TEXT_B}>
                        {props.item.country}
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={getFont(13, 'black', DEFAULT_FONT)}>
                            {languages["vendor"][code]+": "}</Text>
                        <Text style={TEXT_B}>
                            { props.item.vendor ? ' ' + props.item.vendor : '-'}
                        </Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={getFont(13, 'black', DEFAULT_FONT)}>
                            {"KG/LT: "}</Text>
                        <Text style={TEXT_B}>
                            { ' ' + props.item.kgl}
                        </Text>
                    </View>
                    { props.item.shape && <View style={{flexDirection: 'row'}}>
                        <Text style={getFont(13, 'black', DEFAULT_FONT)}>
                            {languages["shape"][code]+": "}</Text>
                        <Text style={TEXT_B}>
                            { ' ' + props.item.shape}
                        </Text>
                    </View> }
                    { distributionIsAvailable(props.item) && <View style={{flexDirection: 'row'}}>
                        <Text style={TEXT_B}>
                            { ' ' + props.item.values.val_1+'%' + ' - ' + props.item.values.val_2+'%' + ' - ' + props.item.values.val_3+'%' + ' - ' + props.item.values.val_4+'%'}
                        </Text>
                    </View> }
                    { <StarRating similarity_score={props.item.similarity}/> }
                    { props.item.tags && props.item.tags.length > 0 && <FlagView tags={props.item.tags} /> }
                </View>

            </View>
        </TouchableOpacity>
    )
}
