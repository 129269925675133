import AsyncStorage from '@react-native-async-storage/async-storage';


//WRITE
export const storeData = async (key, value) => {
    try {
      await AsyncStorage.setItem('@'+key, value)
    } catch (e) {
      // saving error
    }
  }


  //READ
export const getData = async (key) => {
    try {
      return await AsyncStorage.getItem('@'+key)
    } catch(e) {
      // error reading value
    }
  }
  
  export const clearData = async () => {
    try {
      return await AsyncStorage.clear()
    } catch(e) {
      // error reading value
    }
  }


export const formatNumber = (num) => {
  return (Math.round(num * 100) / 100).toFixed(0);
}


export const roundToPlace = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
}

export const isNumber = (t) => {
  var reg = /^\d+$/;
  return reg.test(t)
}

export const isNumberFloat = (t) => {
  var reg = /^(\d*)([.]\d{0,2})?$/
  

  //var reg =/\d*+(\.\d+)?$/;

  return reg.test(t)
}

export const isAccept = (t, value) => {
  if (value === '') {
    return true
  }
  if (t === 'int') {
      return isNumber(value)
      
  }
  if (t === 'float') {
    return isNumberFloat(value)
  } 
  return true
}

export const formatDate = (d) => {
  var mydate = new Date(d);
  return mydate.getFullYear() + "-" + mydate.getMonth() + "-" + mydate.getDay();
}

export const validPassword = (pwd, pwdConfirm) => pwd.length > 0 && pwdConfirm === pwd

export const validEmail = (email) => {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
  
}

export const minusOne = (v) => {
  var floatPattern = /^[+-]?\d+(\.\d+)?$/;
  return floatPattern.test(v) ? parseFloat(v) <= 1 && parseFloat(v) > 0 : false;  
}

export const numberOnly = (v) => {
  var intPattern = /^\d+$/;
  return intPattern.test(v);  
}


export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href || ''
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const removeParameter = () => {
  window.location = window.location.href.split('/password')[0]
}


export const distributionIsAvailable = (item) => {
  return !(item.values.val_1 === 0 && item.values.val_2 === 0 && item.values.val_3 === 0 && item.values.val_4 === 0)
}