import { formatNumber } from '../utils'

export const DISTANCE_MM = {
    "dm": "mm", "uk": "inch", "us": "inc", "conversion_to_UK": 0.0393701, "conversion_to_US" : 0.0393701
}

export const AREA_TIME = {
    "dm": "Ha/h", "uk": "ac/h", "us": "ac/h", "conversion_to_UK": 2.471053, "conversion_to_US" : 2.471053
}

export const MASS_VOLUME = {
    "dm": "kg/lt", "uk": "lbs/gal [UK]", "us": "lbs/gal [UK]", "conversion_to_UK": 10.0224, "conversion_to_US" : 8.3454
}

export const WEIGHT = {
    "dm": "kg", "uk": "lbs", "us": "lbs", "conversion_to_UK": 2, "conversion_to_US" : 2
}

export const DISTANCE = {
    "dm": "m", "uk": "feet", "us": "feet", "conversion_to_UK": 3.28084, "conversion_to_US" : 3.28084
}

export const DISTANCE_CM = {
    "dm": "cm", "uk": "inch", "us": "inch", "conversion_to_UK": 0.393701, "conversion_to_US" : 0.393701
}

export const VELOCITY = {
    "dm": "km/h", "uk": "mph/h", "us": "mph/h", "conversion_to_UK": 0.621371, "conversion_to_US" : 0.621371
}


export const QUANTITY_DISTANCE = {
    "dm": "kg/ha", "uk": "lbm/ac", "us": "lbm/ac", "conversion_to_UK": 0.89, "conversion_to_US" : 0.89
}


export const MASS_TIME = {
    "dm": "kg/min", "uk": "lbs/min", "us": "lbs/min", "conversion_to_UK": 2.20462, "conversion_to_US" : 2.20462
}

const allUnites = [DISTANCE, VELOCITY, QUANTITY_DISTANCE, MASS_TIME, WEIGHT, MASS_VOLUME, AREA_TIME, DISTANCE_MM, DISTANCE_CM]




export const metrics_all = [
    { "title": "DECIMAL METRIX", "sigla": "dm", "subtitle": allUnites.map( el => el.DM ).join(', ') },
    { "title": "UK IMPERIAL SYSTEM", "sigla": "uk", "subtitle": allUnites.map( el => el.UK ).join(', ') },
    { "title": "UNITED STATES CUSTOMARY UNITS", "sigla": "us", "subtitle": allUnites.map( el => el.US ).join(', ')}
]


export const substituteForMetrics = (label, metrics) => {
    var label_final = label
    metrics.forEach( (mm, index) => {
        const to_replace = '$'+index
        label_final = label_final.toString().replace(to_replace, mm)
    })
    return label_final
}

export const converValue = (value, metrics, metricsUnity) => {
    const mul = metrics === 'dm' ? 1 :  ( metrics === 'uk' ? metricsUnity.conversion_to_UK : metricsUnity.conversion_to_US )
    return formatNumber(value * mul)
}

export const getUnity = (metrics, metricsUnity) => {
    return metricsUnity[metrics]
}