import React, { useEffect, useContext } from "react";
import { View, Text, FlatList, TouchableOpacity } from 'react-native' 
import { metrics_all } from '../../metrics'
import { storeData } from "../../utils";
import { TEXT_B, LIGHT_GRAY, GREEN, DARK_GRAY } from '../../style'
import { UserContext } from '../../utils/context'
//import Icon from 'react-native-ionicons';
import { CustomIcon } from "../General/CustomIcon";
import { METRICS } from "../../constants";


export function CircleTextAndDetailAndSubTitle(props) {
    return (
        <>
        <TouchableOpacity onPress={props.onClickRow} style={{paddingVertical: 15}}>
            <View style={{flexDirection: 'row', alignItems:'center'}}>
                <View style={{backgroundColor: LIGHT_GRAY, alignItems: 'center', justifyContent: 'center',
                        width:30, height:22, borderRadius: 3}}>
                    <Text style={TEXT_B}>{props.sigla.toUpperCase()}</Text>
                </View>
                <Text style={[TEXT_B, {flex: 2, marginHorizontal: 12}]}>
                    { props.title }
                </Text>
                { props.selected && <CustomIcon name={'checkmark'} size={20} color={GREEN} /> }
            </View>
           
           
        </TouchableOpacity>
        <View style={{borderBottomColor: DARK_GRAY, borderBottomWidth: 0.8}}/>
        </>
    )
}


export default function ChooseMetrics(props) {

    const { setMetrics, metrics, config, user, updateUserApi } = useContext(UserContext);
    
    useEffect(() => {
        if (config === false) {
            storeData(METRICS, metrics).then( _ => {
                setMetrics(metrics)
            })
        }
    })
    
    return (
        <FlatList style={{padding: config ? 12 : 0}}
                data={metrics_all} keyExtractor={(_, index) => index.toString()}
                renderItem={ item => 
                    <CircleTextAndDetailAndSubTitle 
                        sigla={item.item.sigla}
                        title={item.item.title} subtitle={item.item.subtitle}
                        selected={metrics===item.item.sigla}
                        onClickRow={() => {
                            storeData(METRICS, item.item.sigla).then( _ => {
                                setMetrics(item.item.sigla)    
                            })
                            if (user) {
                                const newUser = Object.assign(user, {"metric_unit": item.item.sigla})
                                updateUserApi(newUser)
                            }
                        }}/>}/>
        
    )

}


