import React, { useEffect, useContext } from "react";
import { View, Text, Image, ScrollView } from 'react-native'
import { DARK_GRAY, getFont, TITLE_B } from "../../../style";
import { UserContext } from '../../../utils/context'
import { substituteForMetrics, getUnity, DISTANCE, converValue } from '../../../metrics'

export default function SecondInstruction(props) {

    const appcontext = useContext(UserContext);
    const { code, metrics, languages } = appcontext
    

    useEffect(() => {
        props.isValid(true)
    })


    return (
        <ScrollView style={{paddingHorizontal: 15}}>
            <Text style={TITLE_B}>
                {languages["secondInstruction_route_information_kit"][code]}
            </Text>
            <Text style={{...getFont(16,DARK_GRAY), marginBottom: 18}}>
                {languages["explain_image"][code]}
            </Text>
            
            <Text style={TITLE_B}>
                {languages["secondInstruction_attention_kit"][code]}
            </Text>
            <Text style={{...getFont(14, DARK_GRAY), marginBottom: 18}}>
                { substituteForMetrics(languages["explain_box"][code], 
                    [
                        converValue(40, metrics, DISTANCE), getUnity(metrics, DISTANCE),
                        converValue(40, metrics, DISTANCE), getUnity(metrics, DISTANCE)
                    ])}
            </Text>
            <View style={{alignItems:'center'}}>
                <Image style={{width:300, height: 230}} 
                       source={require('../../../images/serpentina.png')}/>
            </View>
        </ScrollView>
    )
}