import React, { useContext } from "react";
import { View, Text,  } from 'react-native'
import { TITLE_B, TEXT_B } from "../../style";
import { UserContext } from '../../utils/context'

export function Disclaimer() {
    
    const appcontext = useContext(UserContext);

    return (
        <View style={{flex:1}}>
            <Text style={TITLE_B}>{ appcontext.languages["disclaimer_title"][appcontext.code]}</Text>
            <Text style={TEXT_B}>
                { appcontext.languages["disclaimer_azienda"][appcontext.code]}
            </Text>
        </View>
    )

}

export function DisclaimerConteiner() {
    
    

    return (
        <View style={{padding:20, flex:1}}>
            <Disclaimer />
        </View>
    )

}