import React from 'react';
import { Text, View } from 'react-native';
import { getFont } from '../../../style';

export default function AdviseMessage(props) {
    return (
        <View style={{marginTop: 20}}>
            <Text style={getFont(17)}>{props.message}</Text>
        </View>
    )

}