import React, { useEffect, useContext } from "react";
import { View, FlatList, Text, TouchableOpacity } from 'react-native' 
import { languages } from '../../langs'
import { storeData } from '../../utils'
//import { setCache, getCache } from '../../api/dcm_api'
import { TEXT_B, SQUARE_SIZE, DARK_GRAY, GREEN  } from "../../style";
import CountryFlag from "react-native-country-flag";
//import Icon from 'react-native-ionicons';
import { CustomIcon } from "../General/CustomIcon";
import { UserContext } from '../../utils/context'
import { LANGUAGE } from "../../constants";

export function LanguageRow(props) {
    return (
        <>
        <TouchableOpacity onPress={props.onClickRow} style={{paddingVertical: 15}}>
            <View style={{flexDirection: 'row', alignItems:'center'}}>
                <CountryFlag isoCode={props.icon} 
                    size={SQUARE_SIZE} style={{width: 28, height: 20, borderRadius: 3}}/>
                <Text style={[TEXT_B, {flex: 2, marginHorizontal: 12}]}>
                    { props.title }
                </Text>
                { props.selected && <CustomIcon name='checkmark' size={20} color={GREEN} /> }
            </View>
        </TouchableOpacity>
        <View style={{borderBottomColor: DARK_GRAY, borderBottomWidth: 0.8}}/>
        </>
    )
}

export default function ChooseLang(props) {

    const { setCode, code, config, user, updateUserApi } = useContext(UserContext);

    useEffect(() => {
        if (config === false) {
            storeData(LANGUAGE, code).then( _ => {
                setCode(code)    
            })
        }
    })
    
    return (
        <FlatList style={{paddingHorizontal: config ? 12 : 0}}
                data={languages} keyExtractor={(_, index) => index.toString()}
                renderItem={ item => 
                    <LanguageRow title={item.item.name} icon={item.item.flag} 
                        iconColor='white' detail={''} 
                        selected={code===item.item.code}
                        onClickRow={() => {
                            //TODO
                            storeData(LANGUAGE, item.item.code).then( _ => {
                                setCode(item.item.code)
                            })

                            if (user) {
                                const newUser = Object.assign(user, {"language": item.item.code})
                                updateUserApi(newUser)
                            }

                        }}/>}/>
        
    )

}


