import { Text, View } from 'react-native'
import { Picker } from "@react-native-picker/picker";
import { getFont } from "../../../style";

export default function SinglePickerText(props){

    return (
      <View style={{marginBottom: 15}}>
        <Text style={[getFont(14), { marginBottom: 3, flex: 1}]}>
            {props.title.toUpperCase()}
        </Text>
        <Picker
            selectedValue={props.selectedValue}
            onValueChange={(itemValue, _) => {
              props.onValueChange(itemValue)}}>
              { props.values.map( p => 
                <Picker.Item key={p.value} 
                    label={String(p.label) + " " + props.unity} 
                    value={p.value}/>) }
        </Picker> 
      </View>
    )
  }