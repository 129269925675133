import React, { useEffect, useState, useRef, useContext } from "react";
import { View, Text, useWindowDimensions, TouchableOpacity } from 'react-native'
import { TEXT_B, container, MEDIUM_B, getFont, BLACK, TITLE_B, GREEN_GRAY, LIGHT_GRAY, GREEN, DARK_GRAY, DEFAULT_FONT } from '../../../style'
import { UserContext } from '../../../utils/context'
//import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';


function BoxCalibration(props) {

    //const [textW, setTextW] = useState(props.el.value)

    const [buttonTouchIndex, setButtonTouchIndex] = useState(16)
    const [isActive, setIsActive] = useState(false)
    
    //const { setMessage, languages, code } = useContext(UserContext);
    const tenTag = [...Array(16).keys()]
    const { width } = useWindowDimensions();

    const getColor = (buttonTouchIndex, i, isActive) => {        
        if (isActive) {
            return LIGHT_GRAY
        } else {
            return i < buttonTouchIndex ? LIGHT_GRAY : GREEN
        }        
    }

    /*const scrollToInput = (event) => {
        const findElement = findNodeHandle(event.target)
        //props.scrollRef.current.scrollToFocusedInput(findElement)
    }*/


    const tt = isActive ? 0 : (buttonTouchIndex-16)*(-1) 

    return (
        <View //KeyboardAwareScrollView TODO
            key={props.index+""} 
            style={{alignItems:'center', margin: 4, justifyContent:'center'}}>
            <Text style={{textAlign:'center', ...TITLE_B}}>{ tt }</Text>
            <View style={{...container.box_no_space, 
                width: width/4*0.7, marginVertical: 12}}>
                { tenTag.map( (t,i) => 
                    <TouchableOpacity 
                        onPress={() => {
                            setButtonTouchIndex(i)
                            const t = i === 15 ? !isActive ? 0 : (i-16)*(-1) : (i-16)*(-1) 
                            props.isValidNumber(t)
                            if (i === 15) {
                                setIsActive(!isActive)
                            } else {
                                setIsActive(false)
                            }
                        }}
                        key={t}   
                        style={{backgroundColor: getColor(buttonTouchIndex, i, isActive), 
                        width: '100%', 
                        height: 10, 
                        marginVertical: 1, borderRadius: 15}}/>)} 
            </View>
            <Text style={{textAlign:'center', ...MEDIUM_B}}>{props.el.label}</Text>
        </View>
    )
}

export default function Calibration(props) {

    
    const [innerCalibration, setInnerCalibration] = useState(props.calibration)
    const appcontext = useContext(UserContext);
    const code = appcontext.code
    var scrollRef = useRef(null)

    const checkValidCalibration = (v) => {
        return v && v <= 16 && v > 0
    }

    const boxes = [
        {
            label: "B", 
            color: GREEN_GRAY, 
            is_valid: checkValidCalibration(innerCalibration.b), 
            value: innerCalibration.b,
            refBox: useRef(null)
        },
        { 
            label: "C", color: GREEN_GRAY, 
            is_valid: checkValidCalibration(innerCalibration.c), 
            value: innerCalibration.c,
            refBox: useRef(null)
        },
        { 
            label: "D", color: GREEN_GRAY, 
            is_valid: checkValidCalibration(innerCalibration.d), 
            value: innerCalibration.d,
            refBox: useRef(null)
        },
        { 
            label: "E", color: GREEN_GRAY, 
            is_valid: checkValidCalibration(innerCalibration.e), 
            value: innerCalibration.e,
            refBox: useRef(null)
        }
    ]

    useEffect(() => {
        Object.keys(innerCalibration)
            .filter(v => checkValidCalibration(innerCalibration[v])).length === 4 ? props.isValid(true) : props.isValid(false)
    }, [innerCalibration])
    
    return (
        <View 
            ref={(ref) => scrollRef.current = ref}  
            style={{paddingHorizontal: 15}}>
            <Text style={TITLE_B}>
                {appcontext.languages["calibration"][code].toUpperCase()}
            </Text>
            <Text style={{...getFont(16, DARK_GRAY), marginBottom: 12}}>
                {appcontext.languages["explain_box_2"][code]}
            </Text>
            
            <View style={{flexDirection: 'row', alignItems:'center', justifyContent:'center'}}>
                { boxes.map( (b, index) => <BoxCalibration refBox={b.refBox} scrollRef={scrollRef}
                        isValidNumber={ n => {
                            const newCalibration = Object.assign({}, innerCalibration)
                            newCalibration[b.label.toLowerCase()] = n
                            setInnerCalibration(newCalibration)
                            props.onCalibrationSet(newCalibration)
                        }}
                        key={index} el={b} index={index} 
                        refBoxNext={ index < boxes.length-1 ? boxes[index+1].refBox : null }/>) }
            </View>
            <Text style={getFont(14, BLACK, DEFAULT_FONT)}>
                {appcontext.languages["explain_update_box_2"][code]}
            </Text>
            <Text style={TEXT_B}>
                {appcontext.languages["explain_update_box_3"][code]}
            </Text>
        </View>
    )
}

