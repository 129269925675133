import React, { useState, useEffect } from "react";


import { Text, Platform, View } from 'react-native';

import ConfigContainer from './src/Components/ConfigContainer';
import languages from './src/langs/language.json'
import { UserContext } from './src/utils/context'
import { getData } from "./src/utils";
import { LANGUAGE, METRICS } from './src/constants'
import { getFavorites, getUser, updateUser } from './src/api/dcm_api.js'
import AppWeb from './AppWeb.js'
import './App.css';

export default function App() {

  const [config, setConfig] = useState(false);
  const [userLogged, setUserLogged] = useState(null);
  const [code, setCode] = useState("it_it");
  const [metrics, setMetrics] = useState("dm");
  const [message, setMessage] = useState(null);
  const [favoritesState, setFavoritesState] = useState({isLoading: false, error: null, response: []});

  const updateUserApi = (newUser) => {
    updateUser(newUser).then( res => {
        if (res.response) {    
            getUserApi()
        } 
    })
  }

  useEffect(() => {
    getData(LANGUAGE).then( lang => {
      if (lang) {
        getData(METRICS).then( unity => {
          if (unity) {
             setConfig(true) 
             setCode(lang)
             setMetrics(unity)
          } else {
             setConfig(false)
          }
         })
      } else {
        setConfig(false)
      }
    })
    getUserApi()

  }, [])

  const getUserApi = () => {
    getUser().then( u => {
      if (u.response) {
        setUserLogged(u.response)
        setCode(u.response.language.toLowerCase())
        setMetrics(u.response.metric_unit)
        setConfig(true)

        setFavoritesState({isLoading: true, error: null, response: []})
        getFavorites().then( f => {
          f.response ? 
            setFavoritesState({isLoading: false, error: null, response: f.response}) : 
            setFavoritesState({isLoading: false, error: f.error, response: []})
        })
      } else {
        setUserLogged(null)
      } 
    })
  }

  const resetState = () => {
    setFavoritesState({isLoading: false, error: null, response: []})
  }


  return (
    <UserContext.Provider value={{user: userLogged, languages, metrics, code, config, message, 
      setMessage, setUserLogged, setCode, setMetrics, resetState, favoritesState, updateUserApi, getUserApi}}>
      {
        config ? 
          (Platform.OS !== 'web' ?<View><Text>mobile</Text></View> : <AppWeb /> ): 
          <div className="App-header">
            <ConfigContainer onFinishConfig={() => setConfig(true)}/>
          </div>
      }
    </UserContext.Provider>
  )
}
