import React, { useState, useContext } from "react";
import { View, Platform } from 'react-native'
import ChooseMachine from "./ChooseMachine.js";
import Calibration from "./Calibration.js";
import ChooseWidth from "./ChooseWidth.js";
import Instruction from "./Instruction.js";
import SecondInstruction from "./SecondInstruction.js";
import Result from "./Result.js";
import { NavigationProcess2 } from '../../Need'
import { AlertAnimated, AlertForWeb } from '../../General/CustomAlert'
import StartKit from './StartKit'
import { UserContext } from '../../../utils/context'
import { DISTANCE, getUnity } from "../../../metrics";
import Paginator from '../../Need/Paginator'

export default function Kit(props) {

    const { languages, code, metrics, message, setMessage } = useContext(UserContext);
    
    const [indexActive, setIndexActive] = useState(0);
    const [start, setStart] = useState(false);
    const [isEnablePrev, setIsEnablePrev] = useState(true);
    const [isEnableNext, setIsEnableNext] = useState(false);

    const [machine, setMachine] = useState(null);
    const [widthWork, setWidthWork] = useState(null);
    const [calibration, setCalibration] = useState({'b': null, 'c': null, 'd': null, 'e': null});

    const startComp = 
            <StartKit {...props} 
                startConfigurator={() => setStart(true)}/>

    const resetProcess = () => {
        setCalibration({'b': null, 'c': null, 'd': null, 'e': null})
        setWidthWork(null)
        setMachine(null)
        setIndexActive(0)
    }

    const pages = [
        {
            "key":0, 
            "component": <ChooseMachine {...props}
                            machine={machine}
                            isValid={(valid) => setIsEnableNext(valid)} 
                            onSelectMachine={(m) => setMachine(m)}/>,
            "title": languages["choose_machine_label"][code],
            "subtitle": machine ? machine.name : '-'
        },
        {
            "key":1, 
            "component": <Instruction {...props}
                            isValid={(valid) => setIsEnableNext(valid)}/>,
            "title": languages["instruction_title_kit"][code],
        },
        {
            "key":2, 
            "component": <ChooseWidth {...props}
                            widthWork={widthWork}
                            onWidthChange={ w => setWidthWork(w)}
                            isValid={(valid) => setIsEnableNext(valid)}/>,
            "title": languages["choose_width_title_kit"][code],
            "subtitle": widthWork ? widthWork + ' ' + getUnity(metrics, DISTANCE) : '-'
        },
        {
            "key":3, 
            "component": <SecondInstruction {...props}
                            isValid={(valid) => setIsEnableNext(valid)}/>,
            "title": languages["secondInstruction_title_kit"][code],
        },
        {
            "key":4, 
            "component": <Calibration {...props}
                            onCalibrationSet={ c => setCalibration(c)}
                            calibration={calibration}
                            isValid={(valid) => setIsEnableNext(valid)}/>,
            "title": languages["calibration_title_kit"][code],
        },
        {
            "key":5, 
            "component": <Result {...props}
                            machine={machine}
                            calibration={calibration} />,
            "title": languages["results"][code],
        }
    ]

    
    return (
        <View style={{flexDirection: 'column', flex: 1}}>
          { start && 
                <NavigationProcess2 
                    start={start}
                    isEnablePrev={isEnablePrev} isEnableNext={isEnableNext}
                    element={pages[indexActive]} numberPage={pages.length}
                    pageActive={indexActive} 
                    homeClick={() => {
                        setStart(!start)
                        resetProcess()
                    }}
                    onNext={(index) => setIndexActive(index+1)} 
                    onPrev={(index) => setIndexActive(index-1)} />
          }
          
          { Platform.OS !== 'web' ? 
            (message &&  <AlertAnimated {...message} onFinish={() => setMessage(null)}/>) : 
            (message && <AlertForWeb {...message} onFinish={() => setMessage(null)}/>)}
          <View style={{flexDirection: 'column', flex: 1}}>
            <View style={{
                paddingHorizontal: (Platform.OS === 'web' ? '10%' : 0),
                paddingTop: 10 ,
                paddingBottom: Platform.OS === 'web' ? 100 : 10}}>
                { start ? pages[indexActive].component : startComp }
            </View>
          </View>
          <View style={{marginBottom: 1}}>
            { start && <Paginator onNext={(index) => setIndexActive(index+1)}
                numberPage={pages.length} pageActive={indexActive} isEnableNext={isEnableNext} /> }
            </View>
        </View>
      )
}

