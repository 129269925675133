
import React from "react";
import { LIGHT_GRAY } from '../../style'
import { isAccept } from '../../utils'

export const CustomInput = React.forwardRef((p, ref) => {
    
    return (
        <input ref={ref} 
            style={{textAlign:'right', border:'None', 
            backgroundColor: LIGHT_GRAY, width: p.width ? p.width : '100%'}} 
            type={ p.is_secure ? (p.showPwd ? "text" : "password") : "text"}
            id={p.name} 
            name={p.name}
            value={p.text} 
            placeholder={p.placeholder} 
            onChange={(e) => {
                isAccept(p.accept, e.target.value) && p.onChangeText(e.target.value) 
            }}/> 
    )
})
