import React from "react";
import { View } from 'react-native'
import { GREEN, CLEAR } from '../../style'

export default function Dot(p){
    const size = 10
    return (
        <View style={{
            marginHorizontal: 3,
            width: size, 
            height: size, 
            backgroundColor: p.isActive ? GREEN : CLEAR,
            borderColor: GREEN,
            borderWidth: 1, 
            borderRadius: size/2}}>
        </View>
    )
}