import React, { useContext } from "react";
import { View, Text, ScrollView, Platform } from 'react-native' 
import { DEFAULT_PAD, TEXT_B, container, TITLE_B, BUTTON_SQUARE_ACTIVE, getFont, WHITE, DARK_GRAY, DEFAULT_FONT_BOLD } from "../../style";
import { CustomIcon } from '../General/CustomIcon'
import { UserContext } from '../../utils/context'

function RowWho(props) {
    return (
        <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 12}}>
            <View style={[BUTTON_SQUARE_ACTIVE, { marginRight: 12}]}>
                <CustomIcon name={props.icons} size={15} color={WHITE} />
            </View>
            <Text style={{ maxWidth: '90%', ...getFont(18, DARK_GRAY, DEFAULT_FONT_BOLD)}}>{props.textRow}</Text>
        </View>
    )
}

export default function ChiSiamo(props) {

    const appcontext = useContext(UserContext);

    return (
        <ScrollView style={{padding: Platform.OS === 'web' ? 0 : DEFAULT_PAD}}>
            <Text style={TEXT_B}>{appcontext.languages["description_azienda"][appcontext.code]}</Text>
            <View style={container.line}></View>
            <Text style={TITLE_B}>{appcontext.languages["contact"][appcontext.code]}</Text>
            <RowWho textRow="(+39) 045 7460455" icons="call" />
            <RowWho textRow="(+39) 045 6545430" icons="print" />
            <RowWho textRow="info@dcmspreaders.com" icons="mail" />
            <RowWho textRow="Via Moschina, 4 37030 Roncà (Verona) ITALIA" icons="navigate" />
            <View style={{width: '100%', height: 100 }}/>
        </ScrollView>
    )
}