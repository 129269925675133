import React, { useEffect, useRef, useState }  from "react";
import { Text, Animated, Alert, View, Platform } from 'react-native'
import { SUCCESS, ERROR, WARNING, getFont, WHITE } from "../../style";


const getColor = (type) => {
    switch (type) {
        case "SUCCESS":
        case "SUCCESS_LARGE":
            return SUCCESS
        case "WARNING":
            return WARNING
        case "ERROR":
            return ERROR
        default:
            return ERROR
    }
}

export function AlertAnimated(props){
    
    const opacity = useRef(new Animated.Value(0)).current;
    const translateY = useRef(new Animated.Value(0)).current;
    const [hasFinish, setHasFinish] = useState( false );
    const time = props.config ? props.config.duration*1000 : 2000

    const fadeIn = () => {
        Animated.sequence([
            Animated.spring(opacity, {
                toValue: 1,
                duration: 100,
                useNativeDriver: true
            }),
            Animated.spring(translateY, {
                toValue: 100,
                duration: time*0.7,
                useNativeDriver: true
            }),
            Animated.timing(opacity, {
                toValue: 0,
                duration: time,
                useNativeDriver: true
            })]).start();
      };
    
    useEffect(() => { 
        
        fadeIn()
        const timer = setTimeout(() => {
            props.onFinish()
            setHasFinish(true)
        }, time+1000);

        hasFinish && clearTimeout(timer)
        return () => props.onFinish() 
    }, [])

    

    return (
        <Animated.View style={{
            opacity: opacity,
            position: 'absolute', top: -100, width: '100%',
            flex: 1,
            alignItems: 'center',
            backgroundColor: getColor(props.type), 
            transform: [{ translateY: translateY }]}}>
            <Text style={getFont(16, WHITE)}>{props.message}</Text>
        </Animated.View>
    )

}

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

export const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

export function AlertForWeb(props){

    const time = props.config ? props.config.duration*1000 : 2000
    const [hasFinish, setHasFinish] = useState( false );

    useEffect(() => { 
        const timer = setTimeout(() => {
            props.onFinish()
            setHasFinish(true)
        }, time+1000);

        hasFinish && clearTimeout(timer)
        // return () => props.onFinish() 
    }, [])

    return (<View style={{backgroundColor: getColor(props.type)}}>
        <Text style={{...getFont(16, WHITE), textAlign: 'center'}}>{props.message}</Text>
    </View>)
}


export const createStandardAlert = (title, message, okAction) => {
    return Alert.alert(
        title,
        message,
        [
          {
            text: "Cancel",
            onPress: () => console.log('cancel action'),
            style: "cancel"
          },
          { 
              text: "OK", 
              onPress: okAction 
          }
        ]
      );
}