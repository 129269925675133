import React, { useContext } from "react";
import { View, Text, Image, useWindowDimensions } from 'react-native'
import { BUTTON_PRIMARY, DARK_GRAY, getFont, TEXT_B, DEFAULT_FONT } from "../../../style";
import { TextButton } from "../../General/Other";
import { UserContext } from '../../../utils/context'


export default function StartKit(props) {

    const appcontext = useContext(UserContext);
    const code = appcontext.code
    const { width } = useWindowDimensions() 
    
    
    return (
            <View style={{flexDirection:  width > 750 ? 'row' : 'column', 
                alignItems: 'center'}}>
                <Image
                    source={require('../../../images/kit_bg_green.png')} 
                    resizeMode="cover" 
                    style={{ justifyContent:'center', marginTop: width > 750 ? 80 : 30, 
                    marginHorizontal: 30,
                    width: 300, height: 300, 
                    borderTopLeftRadius: 20,
                    borderBottomRightRadius: 20}}/>
                <View style={{paddingTop: width > 750 ? 80 : 30, paddingHorizontal: 30}}>
                    <Text style={[ getFont(42, DARK_GRAY, DEFAULT_FONT), {
                        marginVertical: 10, width: "80%",lineHeight: 50}]}>
                        {appcontext.languages["start_kit_title_kit"][code]}
                    </Text>
                    <Text style={[ TEXT_B, {width: "80%", marginBottom: 20} ]}>
                        {appcontext.languages["start_kit_body_kit"][code]}
                    </Text>
                    <View style={{alignItems: 'flex-start'}}>
                        <TextButton
                            title={appcontext.languages["start"][code]}
                            onPress={() => appcontext.user ? 
                                    props.startConfigurator(true) : 
                                    appcontext.setMessage({message: appcontext.languages["element_not_save"][code], type: "ERROR"})} 
                            type={BUTTON_PRIMARY}/>
                    </View>
                </View>
            </View>
    )
}