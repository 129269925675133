import { useContext } from 'react'
import { View, Text, Platform, Image, ScrollView, useWindowDimensions } from 'react-native'
import { getFont, WHITE, GREEN, DARK_GRAY, LIGHT_GRAY, DEFAULT_FONT, DEFAULT_FONT_LIGHT, DEFAULT_FONT_BOLD } from '../../../style'
import { UserContext } from '../../../utils/context'
import { RowResult } from "../../General/Other";
import { distributionIsAvailable } from '../../../utils'

export function PropertyRow(props){

    return (
        <View style={{flexDirection: 'row', marginVertical: 5}}>
            <Text style={[getFont(14, DARK_GRAY, DEFAULT_FONT), {flex: 2}]}>{props.leftText}</Text> 
            <Text style={[getFont(14, DARK_GRAY, DEFAULT_FONT_LIGHT), { flex:2, textAlign: 'right'}]}>{props.rightText}</Text>
        </View>
    )
}





export default function InfoFertilizer(props){
    
    const { languages, code } = useContext(UserContext);
    const { width } = useWindowDimensions();
    

    const item = Platform.OS === 'web' ? props.fertilizer : props.route.params.fertilizer 
    const line = <View style={{backgroundColor:DARK_GRAY, width: 0.5}}/>
    
    
    return (
        <ScrollView style={{flex: 1, paddingVertical: Platform.OS === 'web' ? 0 : 30}}>
                { Platform.OS === 'web' ? <View>
                    <Image source={{'uri':item.img_url}} 
                        style={[
                            { height: 150 }
                        ]}>
                    </Image>
                </View> :
                <View style={{alignItems: 'center', marginBottom: 10}}>
                    <Image source={{'uri':item.img_url}} 
                        style={[
                            { width: width*0.5, height: width*0.5 }
                        ]}>
                    </Image>
                </View> }
            
                <Text style={[getFont(22, GREEN, DEFAULT_FONT), { textAlign: 'center', marginBottom: Platform.OS === 'web' ? 10 : 20}]}>{ item.name }</Text>
                <Text style={[getFont(18, DARK_GRAY, DEFAULT_FONT_LIGHT), { paddingHorizontal: 15}]}>
                    {languages['credits'][code].toUpperCase()}
                </Text>
                <View style={{backgroundColor: Platform.OS === 'web' ? LIGHT_GRAY : WHITE, padding: 20, marginBottom: Platform.OS === 'web' ? 15 : 40}}>
                    <PropertyRow leftText={languages['family'][code]} rightText={item.family} />
                    <PropertyRow leftText={languages['country'][code]} rightText={item.country} />
                    <PropertyRow leftText={languages['shape'][code]} rightText={item.shape} />
                    <PropertyRow leftText={languages['vendor'][code]} rightText={item.vendor} />
                    {/* <PropertyRow leftText={languages['granularity_mean'][code]} rightText={item.avg_grain_size} /> */}
                </View>
                { distributionIsAvailable(item) &&  <Text style={[getFont(18, DARK_GRAY, DEFAULT_FONT_LIGHT), { paddingHorizontal: 15}]}>
                    {languages['distribution'][code].toUpperCase()}
                </Text> }
                { distributionIsAvailable(item) && 
                <View style={{backgroundColor: Platform.OS === 'web' ? LIGHT_GRAY : WHITE, paddingVertical: 20, flexDirection: 'row'}}>
                    <RowResult size_result_font={getFont(22, DARK_GRAY, DEFAULT_FONT_BOLD)}
                        label_left={"< 2.0 mm"}
                        label_right={item.values.val_1+"%"}/>
                    { line}
                    <RowResult size_result_font={getFont(22, DARK_GRAY, DEFAULT_FONT)}
                            label_left={"2.0 - 3.3 mm"}
                        label_right={item.values.val_2+"%"}/> 
                    { line}
                    <RowResult size_result_font={getFont(22, DARK_GRAY, DEFAULT_FONT)}
                        label_left={"3.3 - 4.75 mm"}
                        label_right={item.values.val_3+"%"}/> 
                    { line}
                    <RowResult size_result_font={getFont(22, DARK_GRAY, DEFAULT_FONT)}
                        label_left={"> 4.75 mm"}
                    label_right={item.values.val_4+"%"}/> 
                </View> }
                
            
        </ScrollView>
    )
}