import { TouchableOpacity, View, Text, ActivityIndicator, Platform } from 'react-native'
import { BUTTON_SQUARE_ACTIVE, BUTTON_SQUARE_NOT_ACTIVE, SQUARE_SIZE, 
    TEXT_B, TITLE_B, DEFAULT_PAD, 
    container, BUTTON_SQUARE_DISABLE, WHITE, GREEN, LIGHT_GRAY, getFont, EXTRA_DARK_GRAY, MEDIUM_GRAY, 
    DEFAULT_FONT_LIGHT, DEFAULT_FONT  } from '../../style'
import { CustomIcon } from '../General/CustomIcon'
//import language from '../../langs/language.json'

export function PageController(props) {

    const renderDot = (dot, pageActive) => {
        return (
          <View key={dot.key} style={{...container.bullet, 
                'backgroundColor': (dot.key === pageActive ? 'red' : 'blue'), 
                'marginLeft':2, 
                'marginRight':2}}/>
        )
    }
  
    return (
        <View style={{...container.row, justifyContent: 'center'}}>
            { props.pages.map( p => renderDot(p, props.pageActive)) }
        </View>
    )
  }
  
export function NavController(props) {

    const is_active_next = props.pageActive !== 0
    const is_active_prev = props.numberPage-1 !== props.pageActive
    const is_disable_next = props.numberPage-1 === props.pageActive || !props.isEnableNext
    
    return (
        <View style={{flexDirection:'row', justifyContent:'center', alignItems: 'center', width: 'auto'}}>
            { is_active_next && <TouchableOpacity 
                onPress={() =>  !(props.pageActive === 0 || !props.isEnablePrev) && props.onPrev(props.pageActive)} 
                style={[ BUTTON_SQUARE_NOT_ACTIVE, { marginRight: 6}]}
                disabled={props.pageActive === 0 || !props.isEnablePrev}>
                <CustomIcon name={'arrow-back'} size={15} color={GREEN} />
            </TouchableOpacity> }
            { is_active_prev && <TouchableOpacity 
                onPress={() => !(props.numberPage-1 === props.pageActive || !props.isEnableNext) && props.onNext(props.pageActive)} 
                style={[ is_disable_next ? BUTTON_SQUARE_DISABLE : BUTTON_SQUARE_ACTIVE, { marginLeft: 6}]}
                disabled={ is_disable_next  }>
                <CustomIcon name={'arrow-forward'} size={15} color={WHITE} />
            </TouchableOpacity> }
            {!is_active_prev && <View style={[ BUTTON_SQUARE_ACTIVE, { marginLeft: 6}]}>
                <CustomIcon name="checkmark" size={22} color={WHITE}/>
            </View> }
        </View>
    )
  }


  export function StepNumber(props) {
      return (
          <View style={{width: SQUARE_SIZE, height: SQUARE_SIZE, backgroundColor: MEDIUM_GRAY, margin:5,
          borderRadius: SQUARE_SIZE/2, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{color: 'black'}}>{props.number}</Text>
          </View>
      )
  }

  export function StepCompleted(props) {
    return (
        <View style={{width: SQUARE_SIZE, height: SQUARE_SIZE, backgroundColor: 'black', margin:5,
            borderRadius: SQUARE_SIZE/2, justifyContent: 'center', alignItems: 'center' }}>
            <CustomIcon name={'checkmark'} size={17} color={WHITE} />
        </View>
    )
}

  export function StepCurrentNumber(props) {
    const subtitle = props.subtitle ? props.subtitle.replace('\n', ' ') : null
    const title = props.title ? props.title : ''
    const maxW = Platform.OS !== 'web' ? {width:220} : null
    
    return (
        <View style={{flexDirection:'row', alignItems:'center', flex: 1, height: 50}}>
            <TouchableOpacity onPress={() => props.homeClick()} 
                style={[BUTTON_SQUARE_ACTIVE, {justifyContent: 'center', alignItems: 'center' }]}>
                <CustomIcon name={'home'} size={17} color={WHITE} />
            </TouchableOpacity>
            <View style={{ marginHorizontal: 10, ...maxW}}>
                <Text ellipsizeMode={'tail'} numberOfLines={1}
                    style={{...getFont(18, EXTRA_DARK_GRAY, DEFAULT_FONT_LIGHT)}}>
                    {props.number + ' - ' + title}
                </Text>
                { subtitle && 
                <Text style={[getFont(14, EXTRA_DARK_GRAY, DEFAULT_FONT), {marginRight: 25}]}>
                    {subtitle}
                </Text> }
            </View>
        </View>
    )
}

export function StepCurrentNumber2(props) {
    const title = props.title ? props.title : ''
    const maxW = Platform.OS !== 'web' ? {width:220} : null
    
    return (
        <View style={{flexDirection:'row', alignItems:'center', flex: 1, height: 50}}>
            <TouchableOpacity onPress={() => props.homeClick()} 
                style={[BUTTON_SQUARE_ACTIVE, {justifyContent: 'center', alignItems: 'center' }]}>
                <CustomIcon name={'home'} size={17} color={WHITE} />
            </TouchableOpacity>
            <View style={{ marginHorizontal: 10, ...maxW}}>
                <Text 
                    style={{...getFont(18, EXTRA_DARK_GRAY, DEFAULT_FONT)}}>
                    { title }
                </Text>
            </View>
        </View>
    )
}



  export function StepNavigation(props) {
    
    return (            
        <StepCurrentNumber2
            homeClick={props.homeClick}
            number={props.pageActive+1} 
            title={props.element.title} 
            subtitle={props.element.subtitle} />
    
    )
  }


export function Loading() {
    return (
        <View style={{margin: DEFAULT_PAD}}>
            <ActivityIndicator size="large" color={GREEN} />
        </View>
        
    )
}

export function Error(props) {
    
    const message = props.error ? (props.error.message || 'Generic error') : 'Generic error'
    // props ? language["error"][props.langSigla] : TODO lingua handle
    return (
        <View style={[container.error_view, { padding: DEFAULT_PAD}]}>
            <Text style={TITLE_B}>{ "Errore"}</Text>  
            <Text style={TEXT_B}>{ message }</Text>
        </View>
    )
}

export function handleLoading(langSigla, isLoading, error, 
        innerComponent, 
        loadingComponent = <Loading />, 
        errorComponet = null) {
    
    const errorC = errorComponet || <Error error={error} langSigla={langSigla}/>
    
    return (
        error ? errorC : (isLoading ? loadingComponent : innerComponent)
    )
}

export function NavigationProcess2(props) {
    return (
        <View style={{backgroundColor: LIGHT_GRAY}}>
          <View style={container.navigation_bar}>
            <StepNavigation pageActive={props.pageActive} 
                homeClick={() => props.homeClick(!props.start)} 
                element={props.element}/>
            <NavController 
                pageActive={props.pageActive} 
                numberPage={props.numberPage}
                onPrev={(index) => props.onPrev(index)} 
                onNext={(index) => props.onNext(index)} 
                isEnablePrev={props.isEnablePrev}
                isEnableNext={props.isEnableNext}/>
          </View>
        </View>
    )
}


export function NavigationProcess(props) {
        
    const is_disable_next = props.numberPage-1 === props.pageActive || !props.isEnableNext

    return (
        <View style={{backgroundColor: LIGHT_GRAY}}>
            <View style={[container.navigation_bar, {alignItems: 'center'}]}>
                <TouchableOpacity 
                    onPress={() =>  !(props.pageActive === 0 || !props.isEnablePrev) && props.onPrev(props.pageActive)} 
                    style={[ BUTTON_SQUARE_NOT_ACTIVE, { marginRight: 6}]}
                    disabled={props.pageActive === 0 || !props.isEnablePrev}>
                    <CustomIcon name={'chevron-back'} size={15} color={GREEN} />
                </TouchableOpacity> 
                <StepNavigation pageActive={props.pageActive} 
                    homeClick={() => props.homeClick(!props.start)} 
                    element={props.element}/>
                <TouchableOpacity 
                    onPress={() => !(props.numberPage-1 === props.pageActive || !props.isEnableNext) && props.onNext(props.pageActive)} 
                    style={[ is_disable_next ? BUTTON_SQUARE_DISABLE : BUTTON_SQUARE_ACTIVE, { marginLeft: 6}]}
                    disabled={ is_disable_next  }>
                    <CustomIcon name={'chevron-forward'} size={15} color={WHITE} />
                </TouchableOpacity> 
            </View>
        </View>
    )
}
