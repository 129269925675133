import { View, Text, Pressable } from 'react-native'
import { MEDIUM_GRAY, WHITE } from '../../style'

export default function CustomSegmentedControl(props) {
    
    
    return (
        <View style={{flexDirection: 'row', 
            padding: 2, borderRadius: 5,
            backgroundColor: MEDIUM_GRAY}}>
            { props.values.map( (p, index) => 
                <Pressable key={index} 
                    onPress={() => props.onChange(index)} 
                    style={{flex: 1, borderRadius: 5,
                        backgroundColor: props.selectedIndex === index ? WHITE : MEDIUM_GRAY}}>
                    <Text style={{textAlign: 'center', paddingVertical: 4}}>{p.toUpperCase()}</Text>
                </Pressable>) }
        </View>
    )  
}