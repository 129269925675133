import { Cache } from "react-native-cache";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { clearData } from '../utils'
import { USER_LOGGED } from "../constants";

const cache = new Cache({
    namespace: "dcm",
    policy: {
        maxEntries: 50000, // if unspecified, it can have unlimited entries
        stdTTL: 0 // the standard ttl as number in seconds, default: 0 (unlimited)
    },
    backend: AsyncStorage
});


const newApi = 'https://app.dcmspreaders.com/api/v2/'
const newApi_normal = 'https://app.dcmspreaders.com/api/' //---->> 

export const cleanCache = () => {
    cache.clearAll()
    clearData()
}
export const setCache = (key, value) => cache.set(key, value)
export const getCache = (key) => cache.get(key)
export const cleanCacheForKey = (key) => cache.remove(key)


function status(res) {
    if (!res.ok) {
        throw res 
    }
    return res;
}

const statusCheck = (res) => {
    /*if (res.status !== 200) {
        throw res 
    }
    return res;*/
    if (res.status === 200 || res.status === 204) {
        return res
    }
    throw res 
}




export const genericFetch = ( key, url, headers, fromCache ) => {
    return fetch(url, headers)
            .then(status)
            .then(res => res.json())
            .then( res => { 
                setCache(key, res)
                return {'response': res, 'error': null}
            }).catch( e => e.json ? 
                { 'error': e.json().message, 'response': fromCache } : 
                { 'error': e.message, 'response': fromCache })
}

const createPayload = (METHOD, body, token = null) => {
    return {
        method: METHOD,
        body: body ? JSON.stringify(body) : null,
        headers: { 
            'Accept': 'application/json', 
            'Content-type':  'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
}

export const simpleFetch = (url, payload = null) => {
    return fetch(url, payload)
            .then( res => statusCheck(res))
            .then( s => s.json())
            .then( s => (s.status === 'error' || s.errors ) ? 
                { 'response': null, 'error': s.message } : 
                { 'response': s, 'error': null }
            )
            .catch( e => e.json ? e.json() : e )
            .then( ee => { 
                return ee.response ? ee : {'error': ee, 'response' : null} 
            })
                //{ 'error': e.json().message, 'response': null } : 
                //{ 'error': e.message, 'response': null })
}

export const getHeaders = (lang = 'it_it') => {
    return { 
        headers: { 'Accept-Language': lang },
    }
}

export const getMachines = (lang) => {
    const key="getMachines_"+lang
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getMachines", getHeaders(lang), fromCache) 
    })
}

export const getOutput = (lang, idMachine, idProduct, w, v, q, idPaletta, blade = 0, cmHeightPianta = 0) => {
    const key="getOutput"+idMachine+"_"+idProduct+"_"+w+"_"+v+"_"+q+"_"+blade
    const params = "?idMachine="+idMachine+"&idFertilizer="+idProduct+"&width="+w+"&velocity="+v+"&quantity="+q+"&blade="+blade+"&idVane="+idPaletta+"&idPaletta="+idPaletta+"&altezzaTardiva="+cmHeightPianta
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getOutput" + params, getHeaders(lang), fromCache) 
    })
}


export const getMeteo = (lat, long) => {
    const params = "?lat="+lat+"&lon="+long
    return simpleFetch(newApi + "getMeteo"+params)
}



export const getFertilizerFamilies = (lang) => {
    const key="getFertilizerFamilies_"+lang
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getFertilizerFamilies", getHeaders(lang), fromCache) 
    })
}

export const getFertilizer = (lang, idFamily, idMachine, blade = 0) => {

    const key="getFertilizerForFamily_"+idFamily+"_"+idMachine+"_"+lang+"_"+blade
    const params = "?idFamily="+idFamily+"&idMachine="+idMachine+"&blade="+blade
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getFertilizerForFamily" + params, getHeaders(lang), fromCache) 
    })
}


export const getWidth = (lang, idFertilizer, idMachine, blade = 0, idPaletta = 1) => {

    const key="getWidth_"+idFertilizer+"_"+idMachine+"_"+lang+"_"+blade+"_"+idPaletta
    const params = "?idFertilizer="+idFertilizer+"&idMachine="+idMachine+"&blade="+blade+"&idPaletta="+idPaletta
    
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getWidth" + params, getHeaders(lang), fromCache) 
    })
}

export const getShapes = (lang) => {
    const key="getShapes_"+lang
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getShapes", getHeaders(lang), fromCache) 
    })
}

export const checkOutputVelocity = (idMachine, idFertilizer, w, v, q, idPaletta) => {
    const params = "?idMachine="+idMachine+"&idFertilizer="+idFertilizer+"&width="+w+"&velocity="+v+"&quantity="+q+"&idPaletta="+idPaletta
    return simpleFetch(newApi + "checkOutputVelocity" + params, null)     
}


export const getSearchSimilarityFertilizer = (lang, distribution, kgl, idShape, idMachine) => {
    
    const params = "?idShape="+idShape+"&d1="+distribution.d1+"&d2="+distribution.d2+"&d3="+distribution.d3+"&d4="+distribution.d4+"&kgl="+kgl+"&idMachine="+idMachine
    const key="?idShape="+idShape+"&d1="+distribution.d1+"&d2="+distribution.d2+"&d3="+distribution.d3+"&d4="+distribution.d4+"&kgl="+kgl+"&idMachine="+idMachine+"_lang"
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "searchSimiliarFertilizers" + params, getHeaders(lang), fromCache) 
    })   
}

//eachRow = 0 (ogni altra fila) / 1 (ogni fila)
//distribution = full (campo pieno) / center (centro fila)
export const getPalette = (idMachine, idProduct, blade = 0, eachRow, distributionRow) => {

    const eachRowValue = eachRow === 'each_row' ? 1 : 0
    const params = blade ? 
        "?idMachine="+idMachine+"&idFertilizer="+idProduct+"&blade="+blade+"&eachRow="+eachRowValue : 
        "?idMachine="+idMachine+"&idFertilizer="+idProduct+"&blade="+blade+"&distribution="+distributionRow
    const key="getPalette_"+params
    
    return cache.get(key).then( fromCache => {
        return genericFetch(key, newApi + "getPalette" + params, getHeaders(), fromCache) 
    })
}

export const getFavorites = () => {
    

    return getCache(USER_LOGGED).then( userToken => {
        if (!userToken) {
            return { 'response': null, 'error': 'Authentication required' }
        } 
        
        const payload = createPayload('GET', null, userToken.authorisation.token)
        return simpleFetch(newApi_normal + "favorites", payload)
    })
}

export const addFavorite = (favorite) => {

    return getCache(USER_LOGGED).then( userToken => {
        if (!userToken) {
            return { 'response': null, 'error': 'Authentication required' }
        } 
        const payload = createPayload('POST', {'data':favorite}, userToken.authorisation.token)
        return simpleFetch(newApi_normal + "favorites", payload) 
    })
}

export const removeFavorite = (id) => {
    return getCache(USER_LOGGED).then( userToken => {
        if (!userToken) {
            return { 'response': null, 'error': 'Authentication required' }
        } 
        const payload = createPayload('DELETE', null, userToken.authorisation.token)
        return simpleFetch(newApi_normal + "favorites/"+id, payload) 
    })


}

// USER API

export const login = (email, pwd) => {
    const payload = createPayload('POST', {"email": email, "password": pwd})
    return simpleFetch(newApi_normal + "login", payload) 
}

export const signup = (user) => {
    const payload = createPayload('POST', user)
    return simpleFetch(newApi_normal + "register", payload) 
}


export const logout = () => {
    return cleanCacheForKey(USER_LOGGED)
}

export const sendLinkForPassword = (email) => {
    const payload = createPayload('POST', {
        "email": email,
        "url": "https://webapp.dcmspreaders.com" //TODO
    })
    return simpleFetch(newApi_normal + "password/email", payload)     
}


export const resetPassword = (token, email, pwd) => {
    const payload = createPayload('POST', {
        "token": token,
        "email": email,
        "password":pwd,
        "password_confirmation":pwd
    })
    return simpleFetch(newApi_normal + "password/reset", payload)     
}

export const getUser = () => {

    return getCache(USER_LOGGED).then( userToken => {
        if (!userToken) {
            return { 'response': null, 'error': 'Authentication required' }
        } 
        const payload = createPayload('GET', null, userToken.authorisation.token)
        return simpleFetch(newApi_normal + "account", payload)
    })
}

export const updateUser = (user) => {  

    return getCache(USER_LOGGED).then( userToken => {
        if (!userToken) {
            return { 'response': null, 'error': 'Authentication required' }
        } 
        const payload = createPayload('PUT', user, userToken.authorisation.token)
        return simpleFetch(newApi_normal + "account", payload)
    })
    
}
