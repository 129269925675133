import React, { useState, useEffect, useContext } from "react";
import { View, Text, Image } from 'react-native'
import {  TEXT_B, getFont, RED, GREEN, TEXT_W } from '../../../style'
import { CustomTextInput } from '../../General/Other'
import { UserContext } from '../../../utils/context'
import { minusOne } from '../../../utils'



export default function KgLTSearch(props) {
    const [kglt, setKglt] = useState(props.kglt);
    const { languages, code } = useContext(UserContext);

    useEffect(() => {
        const yesSearch = minusOne(kglt)
        props.isValid(yesSearch)
        yesSearch && props.onSelectKgLT(kglt)
    }, [kglt])

    const space = <View style={{height:17}}/> 

    return (
        <View style={{paddingHorizontal: 15}}>
            <Text style={TEXT_B}>{"KG/LT"}</Text>
            <CustomTextInput
                accept="float"
                keyboardType="numbers-and-punctuation"
                placeholder={""} 
                text={String(kglt)}
                onChangeText={(g) => {
                    setKglt(g)
                }}/>
            { !minusOne(kglt) ? <Text style={{textAlign: 'right', ...getFont(13, RED)}}>
                {languages["string_must_be_number"][code]}
            </Text> : space}
            <View style={{alignItems: 'center', marginVertical: 10}}>
                <View style={{height: 130, width: 130}}>
                    <Image style={{height: 160, width: 160, resizeMode: 'contain'}} 
                        source={require('../../../images/home_img.png')}/>
                    <View style={{position: 'absolute', 
                        borderRadius: 20,
                        bottom: -10, right: -40, height: 40, width: 40, 
                        justifyContent:'center',
                        backgroundColor:GREEN, alignItems: 'center'}}>
                        <Text style={TEXT_W}>KG/LT</Text>
                    </View>
                </View>
            </View>
            {/* <YouTubeTextButton youtubeID={youtube_link['distribution']}
                type={BUTTON_FLAT_LINK} 
                title={languages["show_video_istruzioni"][code]} /> */}
        </View>
    )
}