import React, { useState, useContext } from "react";
import { View, Text } from 'react-native' 
import { TEXT_B, CONTAINER_PAD, BUTTON_PRIMARY, getFont, RED, DARK_GRAY } from "../../../style";
import { CustomTextInput, TextButton } from '../../General/Other'
import { sendLinkForPassword } from '../../../api/dcm_api'
import { validEmail } from '../../../utils'
import { UserContext } from '../../../utils/context'

export function SendMessageOK() {

    const { languages, code } = useContext(UserContext);

    return (
        <View>
            <Text style={getFont(22, DARK_GRAY)}>{languages["pass_reset"][code]}</Text>
            <Text style={getFont(13, DARK_GRAY)}>{languages["email_send"][code]}</Text>
            <Text style={getFont(13, DARK_GRAY)}>{languages["link_send"][code]}</Text>
        </View>
    )
    
}

export default function ForgotPwd(props) {
    
    const { languages, code } = useContext(UserContext);
    const [username, setUsername] = useState("");
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});

    

    const sendEmail = (email) => {
        setStateApi({isLoading: true, error: null, response: null})
        sendLinkForPassword(email).then( response => {
            if (response.response) {
                setStateApi({isLoading: false, error: null, response: response.response})
            } else {
                setStateApi({isLoading: false, error: response.error, response: null})
            }
        })
    }

    return (
        <View style={{paddingHorizontal: CONTAINER_PAD}}>
            { stateApi.response ? <SendMessageOK /> : <View>
                <Text style={[TEXT_B, {textAlign: 'left', marginVertical: 10}]}>
                {languages["insert_your_email"][code]}
                </Text>
                <Text style={TEXT_B}>EMAIL</Text>
                <CustomTextInput 
                    accept="string" name="name_forgot"
                    text={username} 
                    placeholder={"e-mail"} 
                    onChangeText={(g) => setUsername(g)}/>
                <TextButton 
                    isLoading={stateApi.isLoading}
                    disabled={ !validEmail(username) }
                    title={languages["recupera_email"][code]} 
                    type={BUTTON_PRIMARY}
                    onPress={() => sendEmail(username)}/>
                {  stateApi.error && <Text style={{textAlign: 'right', ...getFont(11, RED)}}>
                    {stateApi.error.message || 'Error'}</Text> }
            </View> }
        </View>
    )
    
}