import React, { useState, useContext } from "react";
import { View, Text } from 'react-native' 
import ChooseLang from "./SettingsLogin/ChooseLang";
import ChooseMetrics from './SettingsLogin/ChooseMetrics';
import { Disclaimer } from './SettingsLogin/Disclaimer';
import { container, TITLE_B, DARK_GRAY, GREEN } from '../style'
import { NavController } from './Need'
import { TextButton } from './General/Other'
import { UserContext } from '../utils/context'

export default function ConfigContainer(p) {

    const [indexActive, setIndexActive] = useState(0);
    const appcontext = useContext(UserContext);
    const isEnableNext = true
    const isEnablePrev = true


    const pages = [
        {
            "key":0, 
            "component": <ChooseLang /> ,
            "title": appcontext.languages["choose_lang"][appcontext.code]
        },
        {
            "key":1, 
            "component": <ChooseMetrics /> ,
            "title": appcontext.languages["choose_unity_title"][appcontext.code]
        },
        {
            "key":2, 
            "component": <Disclaimer /> ,
            "title": '' //appcontext.languages["disclaimer_title"][appcontext.code]
        },
    ]

    return (
        <View style={{backgroundColor:DARK_GRAY, 'flex': 1}}>
            <View style={container.config_fow_web}>
                <View style={{paddingBottom: 12}}>
                    <Text style={TITLE_B}>{pages[indexActive].title}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row'}}>  
                    { pages[indexActive].component}
                </View>
                <View style={{flexDirection: 'row', alignContent: 'center', justifyContent: 'center', marginTop: 12}}>
                    <NavController 
                        pageActive={indexActive} numberPage={pages.length}
                        onPrev={(index) => setIndexActive(index-1)} 
                        onNext={(index) => setIndexActive(index+1)} 
                        isEnablePrev={isEnablePrev}
                        isEnableNext={isEnableNext}/>
                    { (indexActive === pages.length -1 ) && 
                        <TextButton 
                            extra_style={{
                                paddingHorizontal: 20,
                                paddingVertical: 5,
                                borderRadius: 14,
                                margin: 5,
                                backgroundColor: GREEN
                            }} 
                            title={ appcontext.languages["agree"][appcontext.code]} 
                            onPress={() => p.onFinishConfig(true)}/> }
                </View>
            </View>
        </View>
    )
}





