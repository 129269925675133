
import React, { useState, useContext, useEffect } from "react";
import { ScrollView, Linking, Platform } from 'react-native'
import { UserContext } from '../../../utils/context'
import { CustomModal } from '../../General/Other'
import { getUnity, AREA_TIME, converValue, VELOCITY, DISTANCE, QUANTITY_DISTANCE } from "../../../metrics";
import { addFavorite, getOutput } from '../../../api/dcm_api'
import { DARK_GRAY } from "../../../style";
import { createStandardAlert } from '../../General/CustomAlert'
import { generateTxt, generateTxtBordura, HeaderButtons, ResultNode, 
    getDistribution, ResultSection, ResultSectionWithOption } from './utils'
import { formatNumber } from '../../../utils'
import { CustomIcon } from '../../General/CustomIcon'
import AdviseMessage from './AdviseMessage'
//import Meteo from './Meteo'

export default function Results(props) {

    const { languages, code, metrics, getUserApi, setMessage, user } = useContext(UserContext);
    const { machine, fertilizer, family, settings, paletta, elettric } = props

    

    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});
    const [outStateApi, setOutStateApi] = useState({isLoading: false, error: null, response: null});
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setOutStateApi({isLoading: true, error: null, response: null})
        if (machine.notes && machine.notes.length > 0 ){
            (Platform.OS === 'web' ? 
            setModalVisible(!modalVisible) : 
            createStandardAlert(languages["attenzione"][code], machine.notes, () => console.log('ok'))) 
        } 
                
        
        getOutput(code, machine.id, fertilizer.id, 
            settings ? settings.width : 0, 
            settings ? settings.velocity : 0, 
            settings ? settings.quantity: 0, 
            paletta ? paletta.id : 0, 
            props.blade ? 1 : 0, 
            props.cmHeightPianta ? props.cmHeightPianta : 0 ).then( success => {
            if (success.response) {
                setOutStateApi({isLoading: false, error: null, response: success.response})
            } else {
                setOutStateApi({isLoading: false, error: success.error, response: null})
            }
        })
    }, [])

    

    const section_input_data = (machine, family, fertilizer, settings, elettric) => {
        return {
            "title":languages["input"][code], //input
            "rows": [
                { 
                    leftText: languages["machine"][code], 
                    rightText: machine ? machine.name : '-',
                },
                { 
                    leftText: languages["family"][code], 
                    rightText: family ? family.name : '-'  },
                { 
                    leftText: languages["product"][code], 
                    rightText: fertilizer ? fertilizer.name : '-'  
                },
                { 
                    leftText: languages["shape"][code], 
                    rightText: fertilizer ? fertilizer.shape : '-'  },
                { 
                    leftText: "Kg/LT", 
                    rightText: fertilizer ? fertilizer.kgl : '-', 
                    actionView: true, 
                },
                { 
                    leftText: languages["distribution"][code], 
                    rightText: fertilizer ? getDistribution(fertilizer.values) : '-' 
                },
                elettric ? null : { 
                    leftText: languages["width"][code], 
                    rightText: settings ? converValue(settings.width, metrics, DISTANCE) + " " +  getUnity(metrics, DISTANCE) : '-'
                },
                { 
                    leftText: languages["velocity"][code], 
                    rightText: settings ? converValue(settings.velocity, metrics, VELOCITY)+ " " + getUnity(metrics, VELOCITY): '-'},
                elettric ? null : { 
                    leftText: languages["quantity"][code], 
                    rightText: settings ? converValue(settings.quantity, metrics, QUANTITY_DISTANCE) + " " + getUnity(metrics, QUANTITY_DISTANCE): '-' 
                }
            ].filter( a => a !== null)
        }
    }

    const section_settings_machine = (out, isEletric = false) => {
        
        const v = out && out.vanesset ? out.vanesset : (out.fallpoints ? out.fallpoints : null)
        const position = (out && out.position) ? out.position.output : null
        const message = out ? out.message : null
        
        return {
            "title":languages["set_output_title"][code], //set_output_title
            "rows": [
                { 
                    leftText: languages["vanes_lenght"][code], 
                    rightText: (v && v.vanesLenghtSet) || '-', 
                    icona: require('../../../images/allung_paletta.png'), 
                    iconaLarge: require('../../../images/paletta_large_bis.png'),
                    visibility: true
                    //youtube: youtube_link["distribution"]
                },
                { 
                    leftText: languages["vanes_position_title"][code], 
                    rightText: (v && v.vanesSet) || '-', 
                    icona: require('../../../images/orient_paletta.png'),
                    iconaLarge: require('../../../images/orient_paletta_large.png'),
                    visibility: true
                    //youtube: youtube_link["distribution"]
                },
                { 
                    leftText: languages["drop_point"][code], 
                    rightText: (v && v.fallPointSet) || '-', 
                    icona: require('../../../images/pointFall.png'),
                    iconaLarge: require('../../../images/pointFall_large.png'),
                    visibility: true

                },
                { 
                    leftText: languages["open_position"][code], 
                    rightText: (position && position.position) || '-', 
                    icona: require('../../../images/openPosition.png'),
                    iconaLarge: require('../../../images/openPosition_large.png'),
                    visibility: !isEletric
                },
                isEletric ? null : { 
                    leftText: languages["velocity_pto"][code], 
                    rightText: (v && v.pto) || '-',
                    icona: require('../../../images/velocitaPTO.png'),
                    iconaLarge: require('../../../images/velocitaPTO_large.png'),
                    visibility: true
                },
                { 
                    leftText: languages["height_a"][code], 
                    rightText: (v && v.heightA) || '-', 
                    icona: require('../../../images/heightA.png'),
                    iconaLarge: require('../../../images/heightAB_large.png'),
                    visibility: true
                },
                { 
                    leftText: languages["height_b"][code], 
                    rightText: (v && v.heightB) || '-', 
                    icona: require('../../../images/heightA.png'),
                    iconaLarge: require('../../../images/heightAB_large.png'),
                    visibility: true,
                    optionalMessage: null
                },
                { 
                    leftText: languages["kg_min"][code] + ' (' +languages["per_lato_message"][code] + ')', 
                    rightText: (position && position.kgminute) || '-', 
                    icona:require('../../../images/kgMin.png'),
                    iconaLarge: require('../../../images/kgMin_large.png'),
                    visibility: true,
                    optionalMessage: message,
                    actionView: true
                }
            ].filter( a => a !== null).filter( a => a.visibility )
        }
    }

    const section_settings_isobus = (out, fertilizer, settings, elettric) => {

        
        
        const v = out && out.vanesset ? out.vanesset : (out && out.fallpoints ? out.fallpoints : null)
        const vri_count = out && out.fallpoints?.fallPoints ? 
            out.fallpoints?.fallPoints.filter( el => el.fallPointPos !== "").length : 
            null
        
        return {
            "title": languages["set_isobus"][code], 
            "rows": [
                elettric ? { 
                    leftText: languages["product"][code], 
                    rightText: fertilizer ? fertilizer.name : '-'
                } : null,
                elettric ? { 
                    leftText: languages["width"][code], 
                    rightText: settings ? converValue(settings.width, metrics, DISTANCE) + " " +  getUnity(metrics, DISTANCE) : '-'
                } : null,
                elettric ? { 
                    leftText: languages["quantity"][code], 
                    rightText: settings ? converValue(settings.quantity, metrics, QUANTITY_DISTANCE) + " " + getUnity(metrics, QUANTITY_DISTANCE): '-' 
                } : null,
                elettric ? { 
                    leftText: languages["velocity_pto"][code], 
                    rightText: (v && v.pto) || '-',
                    icona: require('../../../images/velocitaPTO.png'),
                    iconaLarge: require('../../../images/velocitaPTO_large.png'),
                    visibility: true,
                } : null,
                { 
                    leftText: languages["t_factor"][code], 
                    rightText: out ? out.calibration_app : '-',  
                },
                vri_count ? { 
                    leftText: languages["regulator_VRI"][code], 
                    rightText: "( " + vri_count + " )", 
                    isNavigation: true, 
                    data: out.fallpoints?.fallPoints,
                    accessoryView: <CustomIcon name="arrow-forward" size={20} color={DARK_GRAY} />
                 } : null,
            ].filter( a => a !== null)
        }
    }


    const section_surface = (out) => {
        const v = out.hourlySurface ? out.hourlySurface : '-'
        return {
            "title": languages["target_flow"][code], 
            "rows": [
                { 
                    leftText: getUnity(metrics, AREA_TIME), 
                    rightText: formatNumber(converValue(v, metrics, AREA_TIME ))
                }
            ].filter( a => a !== null)
        }
    }

    

    const section_bordura = (out, title) => {
        const v_pto = out.pto
        const v_set = out.set
        return {
            "title": title,
            "title_segmented": [languages["settings"][code], languages["pto"][code]],
            "rows": [
                { 
                    leftText: languages["marginal"][code], 
                    rightText: [v_set?.marginal, v_pto?.marginal],  
                    icona: require('../../../images/marginale.png'),
                    iconaLarge: require('../../../images/marginale_large.png'),
                    visibility: true
                },
                { 
                    leftText: languages["boundary"][code], 
                    rightText: [v_set?.boundary, v_pto?.boundary], 
                    icona: require('../../../images/confine.png'),
                    iconaLarge: require('../../../images/confine_large.png'),
                    visibility: true

                },
                { 
                    leftText: languages["fossato"][code], 
                    rightText: [v_set?.ditch, v_pto?.ditch], 
                    icona: require('../../../images/fossato.png'),
                    iconaLarge: require('../../../images/fossato_large.png'),
                    visibility: true
                },
            ].filter( a => a !== null)
        }
    }

    const section_flow_reduction = (out) => {
        const v_comp = out.computer
        const v_mach = out.machine
        return {
            "title": languages["reduction"][code], //reduction
            "title_segmented": [languages["computer"][code], languages["mechanical"][code]],
            "rows": [
                { 
                    leftText: languages["marginal"][code], 
                    rightText: [v_comp?.marginal, v_mach?.marginal],  
                    icona: require('../../../images/marginale.png'),
                    iconaLarge: require('../../../images/marginale_large.png'),
                    visibility: true
                },
                { 
                    leftText: languages["boundary"][code], 
                    rightText: [v_comp?.boundary, v_mach?.boundary], 
                    icona: require('../../../images/confine.png'),
                    iconaLarge: require('../../../images/confine_large.png'),
                    visibility: true
                },
                { 
                    leftText: languages["fossato"][code], 
                    rightText: [v_comp?.ditch, v_mach?.ditch], 
                    icona: require('../../../images/fossato.png'),
                    iconaLarge: require('../../../images/fossato_large.png'),
                    visibility: true
                },
            ].filter( a => a !== null)
        }
    }

   

    const createBody = (out) => {
        const input_sec = generateTxt(section_input_data(machine, family, fertilizer, settings))
        const surface = out.response ? generateTxt(section_surface(out.response)) : ""
        const sett_machine = out.response ? generateTxt(section_settings_machine(out.response, elettric)) : ""
        const isobus = out.response ? generateTxt(section_settings_isobus(out.response, fertilizer, settings, elettric)) : ""
        const b_standad = out.response?.border?.standard ? generateTxtBordura(section_bordura(out.response?.border?.standard, languages["border_standard"][code])) : ""
        const b_optional = out.response?.border?.optional ? generateTxtBordura(section_bordura(out.response?.border?.optional, "Bordura optional")) : ""
        const reduction = out.response?.reduction?.optional ? generateTxtBordura(section_flow_reduction(out.response?.reduction?.optional)) : ""
        const note = fertilizer.description
        const fall = out.response?.fallpoints?.fallPoints ? out.response?.fallpoints?.fallPoints : []
        var fallpoints_v = ""
        fall.forEach( f => {
            fallpoints_v = fallpoints_v + "\t\t" + f.fallPointPos + ": " + f.meters + "\n"
        })
        return input_sec + surface + sett_machine + isobus + fallpoints_v + b_standad + b_optional + reduction + note
    }

    
    
    
    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <HeaderButtons 
                    isLoadingSave={stateApi.isLoading}
                    onSaveClick={() => {
                        
                        setStateApi({isLoading: true, error: null, response: null})
                        addFavorite({ "machine": machine, "family": family, "product": fertilizer ,"settings": settings})
                            .then( s => {
                                getUserApi()
                                if (s.response){
                                    setStateApi({isLoading: false, error: null, response: s.response})
                                    setMessage({message: languages["element_save"][code], type: "SUCCESS"})
                                } else {
                                    setStateApi({isLoading: false, error: s.error, response: null})
                                    setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                                }
                            })   
                    }} 
                    onShareClick={() => {
                        if (user) {
                            const body = createBody(outStateApi)
                            const subject = "Result"
                            const mittente = "dcm@spreadshit.com"
                            Linking.openURL(`mailto:${mittente}?subject=${subject}&body=${body}`)
                        } else {
                            setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                        }
                        
                    }} />
            {/* { user && <Meteo /> }   */}
            { outStateApi.response && <ResultNode notes={fertilizer.description}/>}
            { Platform.OS === 'web' && <CustomModal 
                    titleModal={'Title'}
                    innerComponet={<AdviseMessage message={machine.notes} />}
                    modalVisible={modalVisible} 
                    onClose={() => setModalVisible(!modalVisible)}/> }
            <ResultSection 
                    elettric={elettric}
                    data={section_input_data(machine, family, fertilizer, settings, elettric)} 
                    navigation={props.navigation} />
            { outStateApi.response && 
                    <ResultSection 
                        elettric={elettric}
                        data={section_surface(outStateApi.response)} 
                        navigation={props.navigation} /> }
            { outStateApi.response && 
                    <ResultSection 
                        elettric={elettric}
                        data={section_settings_machine(outStateApi.response, elettric)} 
                        navigation={props.navigation} /> }
            { outStateApi.response && elettric && 
                    <ResultSection 
                        elettric={elettric}
                        data={section_settings_isobus(outStateApi.response, fertilizer, settings, elettric)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.border?.standard && 
                    <ResultSectionWithOption 
                        data={section_bordura(outStateApi.response?.border?.standard, 'TODO lingua (paletta special)'/*languages["border_standard"][code]*/)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.border?.optional && 
                    <ResultSectionWithOption 
                        data={section_bordura(outStateApi.response?.border?.optional, languages["border_standard"][code])} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.reduction?.optional && 
                    <ResultSectionWithOption 
                        data={section_flow_reduction(outStateApi.response?.reduction?.optional )} 
                        navigation={props.navigation} /> }
        </ScrollView>
        
    )
}