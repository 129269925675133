import React, { useEffect, useState, useContext }  from "react";
import { View, Text, ScrollView } from 'react-native'
import { GREEN, LIGHT_GRAY, getFont, BUTTON_SECONDARY, DARK_GRAY, RED, DEFAULT_FONT } from '../../../style'
import { TextButton } from '../../General/Other'
import { CustomTextInput } from '../../General/Other'
import { UserContext } from '../../../utils/context'
import _ from 'underscore'


export function Distribution(p) {

    
    const tenTag = [...Array(10).keys()]
    
    const getColor = (pp, i, total) => {
        if (Math.round(pp/10) <= 9-i) {
            return LIGHT_GRAY
        } else {
            return total <= 100 ? GREEN : RED
        }
    }

    return (
        <View style={{flex: 1, alignItems:'center', flexDirection:'column', paddingHorizontal: 5}}>
            <Text 
                style={[getFont(15, DARK_GRAY, DEFAULT_FONT), 
                { width:'100%', textAlign: 'center' }]}>{p.titleDistribution}
            </Text>
            <CustomTextInput 
                accept="int"
                keyboardType="numeric"
                text={ p.value === 0 ? '': String(p.value)} onChangeText={ e => {
                const newValue = e === "" ? "" : parseInt(e)
                p.onInsertDistribution(newValue)
            }} textLabel={p.titleDistribution}/>
            { tenTag.map( (t,i) => 
                <View key={t} 
                    style={{backgroundColor: getColor(p.value, i, p.total), 
                    width: '100%', 
                    height: 10, 
                    marginVertical: 3, borderRadius: 15}}/>)} 
        </View>
    )
}


export function DistributionAll(props) {


    const dist = props.distribution
    const dist_title = ["<2.0", "2.0-3.3" , "3.3-4.75" , ">4.45"]
    const [totalArray, setTotalArray] = useState([dist.d1, dist.d2, dist.d3, dist.d4]);
    
    
    useEffect(() => {
        setTotalArray([props.distribution.d1, 
            props.distribution.d2, 
            props.distribution.d3, props.distribution.d4])
    }, [props.distribution])

    return (
        <View style={{flexDirection: 'row' }}>
            { Object.keys(dist).map( (d, index) => 
                <Distribution 
                    total={props.total}
                    value={totalArray[index]} 
                    key={index}
                    titleDistribution={dist_title[index]} 
                    onInsertDistribution={ a => {
                        const newTotalArray = [...totalArray.slice(0, index), a, ...totalArray.slice(index+1, 4)]
                        setTotalArray(newTotalArray)
                        props.onDistributionSet(_.object(['d1', 'd2', 'd3','d4'], newTotalArray))
                    }}/>
                )}
        </View>
    )
}



export default function SearchForSimilarity(props) {

    const { languages, code } = useContext(UserContext);
    const [distribution, setDistribution] = useState(props.distribution);

    const getTotal = (distribution) => {
        
        return  (parseInt(distribution["d1"] === '' ? 0 : distribution["d1"]) + 
            parseInt(distribution["d2"] === '' ? 0 : distribution["d2"]) +
            parseInt(distribution["d3"] === '' ? 0 : distribution["d3"]) +
            parseInt(distribution["d4"] === '' ? 0 : distribution["d4"]) )
        
    }

    const getTotalLabel = (distribution) => {
        const make = getTotal(distribution)
        
        if (make === 100) {
            return String(make) + "%"
        } else {
            return make <= 100 ? 
                String(make) + "% (" + String(100 - make) + " " + languages['to_go_missing'][code] : 
                String(String(make) + "%" + " " + languages['max_100'][code])
        }
        
    }

    useEffect(() => {
        const yesSearch = (getTotal(distribution) === 100)
        props.isValid(yesSearch)
        yesSearch && props.onSelectDistribution(distribution)
    }, [distribution])

    const space = <View style={{height:31}}/> 


    return (
        <ScrollView showsVerticalScrollIndicator={false} style={{marginHorizontal: 10}}>
            <Text style={[getFont(24, getTotal(distribution) > 100 ? RED : GREEN, DEFAULT_FONT), { textAlign: 'right'}]}>
                {getTotalLabel(distribution)}</Text>
                
            <DistributionAll
                total={getTotal(distribution)} 
                distribution={distribution} 
                onDistributionSet={d => {
                    setDistribution(d)
                    
                }}/>
            <TextButton 
                title={languages["reset"][code].toUpperCase()} 
                onPress={() => setDistribution({d1: 0, d2: 0, d3: 0, d4: 0})}  
                extra_style={{ marginVertical: 10, flex: 1, marginLeft: 5 }}
                type={BUTTON_SECONDARY} />
            { getTotal(distribution) > 100 ? 
                    <Text style={[getFont(20, RED), { textAlign: 'right', marginBottom: 12}]}>
                        {languages["max_percent"][code]}
                    </Text> : 
                    space } 
            {/* <YouTubeTextButton youtubeID={youtube_link['distribution']}
                type={BUTTON_FLAT_LINK} 
                title={languages["show_video_istruzioni"][code]} /> */}
        </ScrollView>
    )
}