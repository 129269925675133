import React, { useEffect, useContext } from "react";
import { View, Text, ScrollView, useWindowDimensions } from 'react-native'
import {  getFont, GREEN, RED, DARK_GRAY, DEFAULT_FONT } from '../../../style'
import { UserContext } from '../../../utils/context'
import { substituteForMetrics, getUnity, VELOCITY, DISTANCE_CM, converValue, QUANTITY_DISTANCE } from '../../../metrics'

function InstructionRow(props){
    
    const size = 40
    const { width } = useWindowDimensions();
    const maxW = width*0.6 
    
    return (
        <View style={{flexDirection: 'row', marginVertical: 14}}>
            <View style={
                {width: size, height: size, borderRadius: size/2, justifyContent:'center', 
                alignItems:'center', borderColor: GREEN, borderWidth: 2, marginRight: 12}}>
                <Text style={[getFont(22, GREEN, DEFAULT_FONT)]}>
                    {props.number}
                </Text>
            </View>
            <View>
                <Text style={getFont(22, DARK_GRAY, DEFAULT_FONT)}>
                    {props.title.toUpperCase()}
                </Text>
                { props.label && <Text style={{...getFont(13, DARK_GRAY, DEFAULT_FONT), maxWidth: maxW}}>
                    {props.label} 
                </Text> }
                { props.label_bold && <Text
                         style={{...getFont(16, DARK_GRAY, DEFAULT_FONT) , maxWidth: maxW}}>{props.label_bold}</Text> }
                { props.label_red && <Text numberOfLines={0} 
                         style={{...getFont(16, RED, DEFAULT_FONT), maxWidth: maxW}}>{props.label_red}</Text> 
                }
            </View>
        </View>
    )
}

export default function Instruction(props) {

    const appcontext = useContext(UserContext);
    const code = appcontext.code
    const metrics = appcontext.metrics
    

    useEffect(() => {
        props.isValid(true)
    })

    
    return (
        <ScrollView style={{paddingHorizontal: 12}}>
            <InstructionRow number={1} 
                    title={appcontext.languages["instruction_title1_kit"][code]}
                    label={appcontext.languages["explain_1"][code]} />
            <InstructionRow number={2} 
                    title={appcontext.languages["instruction_title2_kit"][code]}
                    label={substituteForMetrics(appcontext.languages["explain_2"][code], [converValue(10, metrics, DISTANCE_CM),getUnity(metrics, DISTANCE_CM),converValue(85, metrics, DISTANCE_CM),getUnity(metrics, DISTANCE_CM),converValue(95, metrics, DISTANCE_CM),getUnity(metrics, DISTANCE_CM)])} 
                    label_bold={substituteForMetrics(appcontext.languages["explain_3"][code], [converValue(5, metrics, VELOCITY), getUnity(metrics, VELOCITY)])}/> 
            <InstructionRow number={3} 
                    title={appcontext.languages["instruction_title3_kit"][code]}
                    label_bold={substituteForMetrics(appcontext.languages["last_explain"][code], [converValue(500, metrics, QUANTITY_DISTANCE), getUnity(metrics, QUANTITY_DISTANCE)])}/>
            <InstructionRow number={4} 
                    title={appcontext.languages["instruction_title4_kit"][code]}
                    label_bold={appcontext.languages["explain_kit_1"][code]} 
                    label_red={appcontext.languages["explain_kit_2"][code]}/>
        </ScrollView>
    )
}


