import React from "react";
import { View, useWindowDimensions } from 'react-native'

import { GREEN } from "../style";

export default function GreenLine() {
    const { width } = useWindowDimensions();
    return (
        <View style={{width: width, height: 4, backgroundColor: GREEN}} />
    )
}