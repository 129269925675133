import React, { useState, useContext } from "react";
import { Text, TouchableOpacity, ScrollView } from 'react-native' 
import { CustomTextInput, TextButton, PwdTextInput } from '../../General/Other'
import { CONTAINER_PAD, TEXT_B, getFont, GREEN, BUTTON_PRIMARY, TEXT_B_RED, DEFAULT_FONT } from '../../../style'
import { login, setCache } from '../../../api/dcm_api'
import { USER_LOGGED } from '../../../constants'
import { UserContext } from '../../../utils/context'

export default function Login(props) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPwd, setShowPwd] = useState(false);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});

    const { setUserLogged, languages, code } = useContext(UserContext);
    

    const loginApi = (user, pwd) => {
        setStateApi({isLoading: true, error: null, response: null})
        login(user, pwd).then( success => {            
            
            if (success.response) {
                setCache(USER_LOGGED, success.response)
                setStateApi({isLoading: false, error: null, response: success.response})
                setUserLogged(success.response.user)
                props.onSuccess(success.response.user) 
            } else {
                setStateApi({isLoading: false, error: success.error, response: null})
            } 
        })
    }

    

    return (
        <ScrollView style={{paddingHorizontal: CONTAINER_PAD, paddingTop: 22}}>
            <Text style={TEXT_B}>{languages["email"][code]}</Text>
            <CustomTextInput 
                accept="string" name="name"
                text={username} 
                placeholder={languages["email"][code]} 
                onChangeText={(g) => setUsername(g)}/>
            <Text style={TEXT_B}>PASSWORD</Text>
            <PwdTextInput
                showPwd={showPwd} name="pwd"
                onPressShowPwd={() => setShowPwd(!showPwd)}
                accept="string"
                text={password} 
                placeholder={"password"} 
                onChangeText={(g) => setPassword(g)}/>
            <TouchableOpacity style={{marginBottom: 5}} 
                onPress={() => props.onPwdClick()}>
                <Text style={getFont(13, GREEN, DEFAULT_FONT)} >
                    {languages["forget_pwd"][code]}
                </Text>
            </TouchableOpacity>
            <TextButton 
                isLoading={stateApi.isLoading}
                disabled={ username.length === 0 || password.length === 0}
                title={languages["login"][code]}
                onPress={() => loginApi(username.toLowerCase(), password)}
                type={BUTTON_PRIMARY}/>
            { stateApi.error ? <Text style={TEXT_B_RED}>{stateApi.error.message}</Text> : <Text></Text> }
        </ScrollView>
    )

}