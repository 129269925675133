import React, { useState, useContext } from "react";
import { View, Text, Switch, ScrollView } from 'react-native' 
import { CustomTextInput, TextButton, PwdTextInput } from '../../General/Other'
import { CONTAINER_PAD, BUTTON_PRIMARY, TEXT_B, getFont, GREEN, WHITE, LIGHT_GRAY, RED } from '../../../style'
import { signup, setCache } from '../../../api/dcm_api'
import { Picker } from "@react-native-picker/picker";
import countries from '../../../utils/countries.json'
import { validPassword, validEmail } from '../../../utils'
import { USER_LOGGED } from '../../../constants'
import { UserContext } from '../../../utils/context'

export default function SignUp(props) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [country, setCountry] = useState("");
    const [countryCode, setCountryCode] = useState(countries[0].name);

    const [showPwd, setShowPwd] = useState(false);
    const [showPwdConfirm, setShowPwdConfirm] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    const { code, metrics, getUserApi, languages } = useContext(UserContext);

    const user = {
        "name": name,
        "last_name":surname,
        "email": username,
        "password": password,
        "password_confirmation": passwordConfirm,
        "country": countryCode,
        "language": code, 
        "metric_unit": metrics 
    }

    const signUpApi = () => {
        setStateApi({isLoading: true, error: null, response: null})
        
        signup(user).then( success => {
            if (success.response) {
                setCache(USER_LOGGED, success.response)
                setStateApi({isLoading: false, error: null, response: success.response})
                props.onSuccess(success.response)
                getUserApi()
            } else {
                setStateApi({isLoading: false, error: success.error, response: null})
            }
        })
    }



    const enableRegister = () => {
        return (name !== "" && surname !== "" 
        && validPassword(password, passwordConfirm) && isEnabled 
        && countryCode !== "" && validEmail(username))
    }

    const space = <View style={{height:17}}/> 


    return (
        <ScrollView style={{paddingHorizontal: CONTAINER_PAD, paddingTop: 22}}>
            <Text style={TEXT_B}>{languages["name"][code]}</Text>
            <CustomTextInput
                accept="string" name="name"
                text={name} 
                placeholder={languages["name"][code]} 
                onChangeText={(g) => setName(g)}/>
            { name === "" ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["missing_name"][code]}</Text> : space }
            <Text style={TEXT_B}>{languages["surname"][code]}</Text>
            <CustomTextInput
                accept="string" name="surname"
                text={surname} 
                placeholder={languages["surname"][code]} 
                onChangeText={(g) => setSurname(g)}/>
            { surname === "" ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["missing_surname"][code]}</Text> : space }

            <Text style={TEXT_B}>{languages["email"][code]}</Text>
            <CustomTextInput
                accept="string" 
                name="email"
                text={username} 
                placeholder={languages["email"][code]} 
                onChangeText={(g) => setUsername(g)}/>
            { !validEmail(username) ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["missing_email"][code]}</Text> : space }
            <Text style={TEXT_B}>PASSWORD</Text>
            <PwdTextInput
                showPwd={showPwd} name="pwd"
                onPressShowPwd={() => setShowPwd(!showPwd)}
                accept="string"
                text={password} 
                placeholder={"password"} 
                onChangeText={(g) => setPassword(g)}/>
            { password.length <= 8 ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["pass_not_correct"][code]}</Text> : space}
            <Text style={TEXT_B}>{languages["confirm_pass"][code]}</Text>
            <PwdTextInput
                showPwd={showPwdConfirm} name="pwd_confirm"
                onPressShowPwd={() => setShowPwdConfirm(!showPwdConfirm)}
                accept="string"
                text={passwordConfirm} 
                placeholder={languages["confirm_pass"][code]}
                onChangeText={(g) => setPasswordConfirm(g)}/>
            { !validPassword(password, passwordConfirm) ? 
                <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["pass_not_equals"][code]}</Text> : 
                space
            }
            <Text style={TEXT_B}>{languages["country"][code]}</Text>
            <Picker
                selectedValue={country}
                onValueChange={(itemValue, itemIndex) => {
                    setCountry(itemValue)
                    setCountryCode(countries[itemIndex].code)
                }}>
                    { countries.map( p => <Picker.Item key={p.code} label={p.name} value={p.name}/>) }
            </Picker>
            

            <View style={{flexDirection: 'row', flex: 1, marginTop: 12}}>
                <Switch 
                    trackColor={{ false: WHITE, true: GREEN }}
                    thumbColor={isEnabled ? WHITE : LIGHT_GRAY}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={isEnabled}/>
                <Text style={[TEXT_B, {textAlign: 'right', flex: 1}]}>
                    {/* Acconsento al trattamento dei dati personali */}
                    {languages["accept_privacy"][code]}
                </Text>
            </View>
            { !isEnabled ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>{languages["field_required"][code]}</Text> : space}
            <TextButton 
                extra_style={{marginTop: 20}}
                disabled={ !enableRegister() }
                isLoading={stateApi.isLoading}
                title={languages["registra_utente"][code]} 
                onPress={() => signUpApi()}
                type={BUTTON_PRIMARY}/>
            {  stateApi.error ? <Text style={{textAlign: 'right', ...getFont(11, RED)}}>
                {stateApi.error.message}</Text> : space}
            <View style={{height: 200}}></View>
        </ScrollView>
    )

}