import React, { useState, useContext } from "react";
import { View, Text, Platform, FlatList, Image, useWindowDimensions, Pressable } from 'react-native'
import { getFont, BLACK, WHITE, DARK_GRAY, container, LIGHT_GRAY, DEFAULT_FONT_BOLD, DEFAULT_FONT_LIGHT } from '../../style'
import { handleLoading } from "../Need";
import { RowResult, IconButton, EmptyResult } from '../General/Other'
import { removeFavorite } from '../../api/dcm_api'
import { UserContext } from '../../utils/context'
import { getUnity, DISTANCE, VELOCITY, QUANTITY_DISTANCE } from "../../metrics";
import { getMapLanguages } from '../../langs'
import CountryFlag from "react-native-country-flag";
import { formatDate } from '../../utils'
import ProgressiveImage from '../General/ProgressiveImage'



export function FavoriteRow(props) {

    const { metrics, code } = useContext(UserContext);
    
    const langs = getMapLanguages()

    const m = props.item.content.machine
    const p = props.item.content.product
    const s = props.item.content.settings
    const time = props.item.updated_at
    const sizeM = Platform.OS === 'web' ? 140 : (props.w / 2)*0.6
    const restW = Platform.OS === 'web' ? 300 : (props.w - sizeM)*0.8

    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});

    const removeFavoriteApi = (f) => {
        setStateApi({isLoading: true, error: null, response: null})
        removeFavorite(f.id).then( success => {
            props.onRefreshPage()
            if (success.response) {
                setStateApi({isLoading: false, error: null, response: success.response})
            } else {
                setStateApi({isLoading: false, error: success.error, response: []})
            }
        })
    }

    
    const line = <View style={{backgroundColor:DARK_GRAY, width: 0.3}}/>

    return (
        <Pressable onPress={props.onItemClick} style={{
            backgroundColor: Platform.OS === 'web' ? LIGHT_GRAY : WHITE, marginVertical: 12, padding: 27, borderRadius: 8}}>
            <View style={{alignItems: 'center', flexDirection: 'row', flex: 1,
                justifyContent: 'flex-end'}}>
                
                <View>
                    
                </View>
            </View>
            <View style={{flexDirection: 'row', marginBottom: 12, alignItems:'flex-start',
                justifyContent:'space-between'}}>
                {/* <Image source={{'uri': m.img_url}} 
                        style={{width: sizeM, height: sizeM, borderRadius: 8, marginRight: 12}}/> */}
                <ProgressiveImage 
                        width={sizeM} height={sizeM}
                        img_url={m.img_url} 
                        style_image={[
                            { height: sizeM, width: sizeM, 
                                backgroundColor: WHITE, resizeMode: 'contain', borderRadius: 8}
                        ]}/>
                <View style={{alignItems:'flex-end'}}>
                    <Text numberOfLine={3} 
                        style={{...getFont(18, BLACK, DEFAULT_FONT_BOLD), 
                        maxWidth:200, marginBottom: 7, textAlign: 'right'}}>
                        {m.name.toUpperCase()}
                    </Text>
                    <View style={{flexDirection: 'row', marginBottom: 10}}>
                        <CountryFlag isoCode={langs[code].flag} 
                            size={35} style={{width: 22, height: 16, borderRadius: 3}}/>
                        <Text style={{...getFont(12, DARK_GRAY, DEFAULT_FONT_LIGHT), marginHorizontal: 8}}>
                            {formatDate(time)}
                        </Text>
                    </View>
                    <IconButton isLoading={stateApi.isLoading} 
                            icon={'trash-outline'} 
                            onPress={() => removeFavoriteApi(props.item)}/>
                    
                </View>
                
                
                
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image source={{'uri': p.img_url}} 
                            style={{width: 30, height: 30, borderRadius: 15, marginRight: 7}}/>
                
                <Text style={getFont(12, DARK_GRAY, DEFAULT_FONT_LIGHT)}>
                        {p.name.toUpperCase()}
                </Text>
            </View>
            <View style={container.line} />
            <View style={{flexDirection: 'row', marginVertical: 12 }}>
                <RowResult size_result_font={getFont(14, DARK_GRAY, DEFAULT_FONT_BOLD)}
                    label_left={"Working width"}
                    label_right={s.width + " " + getUnity(metrics, DISTANCE)}/>
                {line}
                <RowResult size_result_font={getFont(14, DARK_GRAY, DEFAULT_FONT_BOLD)}
                    label_left={"Velocity"}
                    label_right={s.velocity + " " + getUnity(metrics, VELOCITY)}/>
                {line}
                <RowResult size_result_font={getFont(14, DARK_GRAY, DEFAULT_FONT_BOLD)}
                    label_left={"Quantity"}
                    label_right={s.quantity + " " + getUnity(metrics, QUANTITY_DISTANCE)}/>
            </View>
        </Pressable>
    )
}

export default function Favorites(props) {

    const { width } = useWindowDimensions();
    const { code, favoritesState, getUserApi, languages } = useContext(UserContext);

    const main = favoritesState.response && favoritesState.response.length === 0 ? 
        <EmptyResult message={languages["no_preferite"][code]}/> : 
        <FlatList data={favoritesState.response} 
            renderItem={ item => 
                <FavoriteRow 
                    onRefreshPage={() => getUserApi()}
                    onItemClick={() => {
                        const new_favorite = Object.assign({}, item.item)
                        props.onFavoriteClick(new_favorite)
                    }}
                    {...item} w={width}/>} />

    
    return (
        <View style={{padding: 12}}>
            { handleLoading(code, favoritesState.isLoading, favoritesState.error, main) }
        </View>
    )
}