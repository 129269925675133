import React, { useState, useEffect, useContext } from "react";
import { View, Text, TouchableOpacity, FlatList, useWindowDimensions } from 'react-native'
import { getMachines } from '../../../api/dcm_api';
import { TITLE_B, GREEN, container, getFont, DARK_GRAY, WHITE, LIGHT_GRAY, DEFAULT_FONT } from '../../../style'
import { handleLoading } from '../../Need/'
import { UserContext } from '../../../utils/context'
import ProgressiveImage from '../../General/ProgressiveImage'
function MachinesRow(props){
    
    return (
        <TouchableOpacity 
            style={{...container.card, margin: 10}} 
            onPress={() => props.onPushRow(props.item)}>
            <ProgressiveImage 
                        width={140} height={140}
                        img_url={props.item.img_url} 
                        style_image={{width: 140, height: 140, 
                            borderColor: props.elementSelected && props.item.id === props.elementSelected.id ? GREEN : LIGHT_GRAY, 
                            borderRadius: 8, resizeMode: 'contain', 
                            backgroundColor: WHITE,
                            borderWidth: 2}}/>
            <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {textAlign: 'center', maxWidth: 130}]}>{props.item.name}</Text>
        </TouchableOpacity>
    )
}


export default function ChooseMachine(props) {
    
    const appcontext = useContext(UserContext);
    const { width } = useWindowDimensions();
    const code = appcontext.code
    
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
    const [machineSelected, setMachineSelected] = useState(props.machine);

    const calc_num = width < 400 ? 2 : Math.floor(width*0.8 / 140)
    

    useEffect(() => {
        props.isValid(props.machine) 
        
        setStateApi({isLoading: true, error: null, response: []})
        getMachines(code).then( success => {

            if (success.response) {
                setStateApi({isLoading: false, error: null, response: success.response.filter( m => !m.has_blade )})
            } else {
                setStateApi({isLoading: false, error: success.error, response: []})
            }
             
        })


    }, [])



    return (
        handleLoading(code, stateApi.isLoading, stateApi.error, 
            <View style={{paddingHorizontal: 15}}>
                <Text style={{margin: 8, ...TITLE_B}}>{appcontext.languages["choose_machine_label"][code]}</Text>
                <View style={{alignItems:'center'}}>
                    <FlatList key={calc_num} numColumns={calc_num} 
                    data={stateApi.response} 
                    renderItem={({item, index}) => 
                        <MachinesRow item={item} index={index} 
                            elementSelected={machineSelected} 
                            onPushRow={(mm) => {
                                setMachineSelected(mm)
                                props.onSelectMachine(mm)
                                props.isValid(true)
                            }}/> } 
                    keyExtractor={item => item.id} />
                </View>
        </View>)
    )
}
