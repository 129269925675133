import React, { useState } from "react";
import { Text, View, Image, TouchableOpacity, Platform } from 'react-native'
import { TextIconButton, ShowImage, CustomModal, YouTubeIcon, TextButton } from '../../General/Other'
import CustomSegmentedControl from '../../General/CustomSegmentedControl'
import { container, BUTTON_PRIMARY, getSquareButton, BUTTON_SECONDARY, WHITE, 
    TEXT_B, WARNING_LIGHT, TITLE_B, getFont, DARK_GRAY, RED, DEFAULT_FONT, DEFAULT_FONT_LIGHT } from "../../../style";
import RegulatorVRI from './RegulatorVRI'
import UpdateValue from './UpdateValue'
import { CustomIcon } from '../../General/CustomIcon'

export function HeaderButtons(props){
    return (
        <View style={{flexDirection: 'row', justifyContent:'flex-end'}}>
            <TextIconButton style={[container.select_button, { marginBotton: 10, marginRight: 5 }]} 
                onPress={() => props.onSaveClick()}
                extra_style={{marginRight: 10}}
                type={BUTTON_PRIMARY}
                icon="star" title="SAVE"/>
            <TextIconButton 
                onPress={() => props.onShareClick()}
                type={BUTTON_SECONDARY}
                icon="share" title="SHARE"/>
        </View>
    )
}

export function ResultNode(props){
    return (
        <View style={{padding: 14, marginTop: 5, backgroundColor: WARNING_LIGHT, borderRadius: 15}}>
            <Text style={[TEXT_B, {padding: 10}]}>
                {props.notes}
            </Text>
        </View>
    )
}

export function ResultNodeAndImage(props){
    return (
        <View style={{padding: 10, marginTop: 10, backgroundColor: WARNING_LIGHT, borderRadius: 15}}>
            <Text style={[TEXT_B, {padding: 10}]}>
                {props.notes}
            </Text>
            {/* <View style={{alignItems:'center'}}>
                <Image style={{height: 50, width: 50, resizeMode: 'contain'}} 
                    source={props.image}/>
            </View> */}
            
        </View>
    )
}

export function ResultRow(props){

    const hasIcon = (props.icona && props.icona !== '') ? true : false

    return (
        <View>
            <TouchableOpacity style={[container.row_result, {marginVertical: 3}]}>
                <TouchableOpacity 
                    onPress={() => props.isNavigation && props.onClickRow(props.data)} 
                    style={{
                        flexDirection:'row',
                        alignItems:'center'}}>
                    {  hasIcon && 
                    <TouchableOpacity onPress={() => props.onClickIcon(props.iconaLarge || props.icona)}>
                    <Image 
                        style={{'width':30,'height':30, marginRight: 7, resizeMode: 'contain'}} 
                        source={props.icona} />
                    </TouchableOpacity>}
                    <View style={ {flexDirection:'row', flex:1, justifyContent: 'space-between', alignItems: 'center'}}>
                        <Text style={getFont(16, DARK_GRAY, DEFAULT_FONT_LIGHT)}>{props.leftText || ''}</Text> 
                        { props.updateValue ? 
                            <Text>{props.updateValue}</Text> :
                            <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {textAlign: 'right'}]}>{props.rightText }</Text> 
                        }
                    </View>
                    { props.youtube && <View style={{paddingLeft: 5}}>
                        <YouTubeIcon youtubeID={props.youtube} askOpen={Platform.OS !== 'web'}/>
                    </View> }
                    { props.accessoryView }
                    { props.actionView && 
                        <TouchableOpacity 
                            onPress={() => {
                                props.onClickActionButton('TODO')
                            }}
                            style={{...getSquareButton(22, true), marginStart: 12}}>
                            <CustomIcon name="checkmark" size={22} color={WHITE}/>
                        </TouchableOpacity>
                    }
                </TouchableOpacity>       
            </TouchableOpacity>
            { props.optionalMessage && 
                <Text style={{...TEXT_B, textAlign: 'right', color: RED}}>
                    {props.optionalMessage}
                </Text> }
           
        </View>
    )
}

export function ResultRowParent(props) {
    
    const [updateValue, setUpdateValue] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [modalVisibleAction, setModalVisibleAction] = useState(false);
    const [dataToShow, setDataToShow] = useState(null);

    return (
        <>
            <CustomModal 
                innerComponet={<ShowImage icon={dataToShow}/>}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/>
            <CustomModal 
                innerComponet={<RegulatorVRI data={dataToShow}/>}
                modalVisible={modalVisible2} 
                onClose={() => setModalVisible2(!modalVisible2)}/>
            <CustomModal 
                innerComponet={<UpdateValue onClickAction={(callback) => {
                    setUpdateValue(callback)
                    setModalVisibleAction(!modalVisibleAction)
                }} 
                data={updateValue || dataToShow}/>}
                modalVisible={modalVisibleAction} 
                onClose={() => setModalVisibleAction(!modalVisibleAction)}/>
            <ResultRow {...props} 
                updateValue={updateValue}
                onClickActionButton={(data) => {
                    if (Platform.OS === 'web') {
                        setDataToShow(props.rightText)
                        setModalVisibleAction(!props.modalVisibleAction)
                    } else {
                        props.navigation && props.navigation.push('UpdateValue', { data: props.rightText })
                    }
                }}
                onClickRow={(data) => {
                    if (Platform.OS === 'web') {
                        setDataToShow(data)
                        setModalVisible2(!props.modalVisible2)
                    } else {
                        props.navigation && props.navigation.push('RegulatorVRI', { data: data })
                    }
                }}
                onClickIcon={(icon) => { 
                    if (Platform.OS === 'web') {
                        setDataToShow(icon)
                        setModalVisible(!props.modalVisible)
                    } else {
                        props.navigation && props.navigation.push('ShowImage', { image: icon })
                    }
            }}/>
        </>
    )
}

export function ResultSection(props){
    
    
    
    return (
        <View style={{ marginTop: 10}}>
            <Text style={TITLE_B}>{props.data.title}</Text>
            {/* { props.data.rows.map( (r,index) => 
                <ResultRow key={index} {...r} 
                    onClickActionButton={(data) => {
                        if (Platform.OS === 'web') {
                            setDataToUpdate(r.rightText)
                            setModalVisibleAction(!modalVisibleAction)
                        } else {
                            props.navigation && props.navigation.push('UpdateValue', { data: r.rightText })
                        }
                    }}
                    onClickRow={(data) => {
                        if (Platform.OS === 'web') {
                            setDataToShow(data)
                            setModalVisible2(!modalVisible2)
                        } else {
                            props.navigation && props.navigation.push('RegulatorVRI', { data: data })
                        }
                    }}
                    onClickIcon={(icon) => { 
                        if (Platform.OS === 'web') {
                            setDataToShow(icon)
                            setModalVisible(!modalVisible)
                        } else {
                            props.navigation && props.navigation.push('ShowImage', { image: icon })
                        }
                }}/>) } */}
            {
                props.data.rows.map( (r, index) => 
                    <ResultRowParent key={index} {...r} navigation={props.navigation}/>)
            }
        </View>
    )
}



export function ResultSectionWithOption(props){

    const [indexSegmented, setIndexSegmented] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [iconToShow, setIconToShow] = useState(null);


    return (
        <View style={{padding: 4, marginTop: 10}}>
            <CustomModal 
                titleModal={'Title'}
                innerComponet={<ShowImage icon={iconToShow}/>}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/>
            <Text style={TITLE_B}>{props.data.title}</Text>
            <CustomSegmentedControl
                    values={props.data.title_segmented}
                    selectedIndex={indexSegmented}
                    onChange={(event) => {
                        setIndexSegmented(event)
                    }} />
            { props.data.rows.map( (r,index) => 
                <ResultRow 
                    key={index} 
                    leftText={r.leftText} 
                    rightText={r.rightText[indexSegmented]} 
                    icona={r.icona} 
                    iconaLarge={r.iconaLarge}
                    onClickIcon={(icon) => { 
                        if (Platform.OS === 'web') {
                            setIconToShow(icon)
                            setModalVisible(!modalVisible)
                        } else {
                            props.navigation && props.navigation.push('ShowImage', { image: icon })
                        }
                         
                    }}/>) }
        </View>
    )
}

const acapo = "\n" //TODO a capo non funziona

export const generateTxtBordura = (objBorder) => {
        
    const title = objBorder.title + acapo
    const title_sub_1 = "\t" + objBorder.title_segmented[0] + ":"+acapo
    const title_sub_2 = "\t" + objBorder.title_segmented[1] + ":"+acapo
    var tripla_1 = ""
    var tripla_2 = ""
    objBorder.rows.forEach( r => {
        tripla_1 = tripla_1 + "\t\t" + r.leftText + ": " + r.rightText[0] + acapo
        tripla_2 = tripla_2 + "\t\t" + r.leftText + ": " + r.rightText[1] + acapo
    })
    return title + title_sub_1 + tripla_1 + title_sub_2 + tripla_2
}

export const generateTxt = (o) => {
    var body = ""
    o.rows.forEach( r => {
        body = body + "\t" + r.leftText + ": " + r.rightText + acapo
    })
    return o.title + acapo + body
}

export const getDistribution = (dist) => {
    return dist.val_1 + "% - " + dist.val_2 + "% - " + dist.val_3 + "% - " + dist.val_4 + "%"
}

export const getBorderValue = (out, key1, key2, key3) => {
    if (out.response && out.response[`${key1}`] && out.response[`${key1}`][`${key2}`] && out.response[`${key1}`][`${key2}`][`${key3}`]) {
        return out.response[`${key1}`][`${key2}`][`${key3}`]
    } else {
        return {
            "marginal": '',
            "boundary": '',
            "ditch": ''
        }
    }
}