import React, { useState } from "react";
import { Text, View, Modal, Image, TouchableOpacity, Platform, ActivityIndicator, Linking } from 'react-native'
import { container, TEXT_B, SPACE_BOTTOM, windowWidth, YOUTUBERED,DEFAULT_PAD,
    BLACK, DARK_GRAY_TRANSPARENT, WHITE, MEDIUM_B, DARK_GRAY, BUTTON_FLAT_GRAY, 
    TITLE_B, getFont, GREEN, BUTTON_PRIMARY, BUTTON_SECONDARY, BUTTON_TAB_ACTIVE, BUTTON_PRIMARY_RED, 
    BUTTON_TAB_NOT_ACTIVE, BUTTON_FLAT, BUTTON_FLAT_LINK, LINK_COLOR, CLEAR, DEFAULT_FONT  } from '../../style'
import { CustomInput } from './CustomInput'
import { createStandardAlert } from "./CustomAlert";
import { CustomIcon } from './CustomIcon';

export function ShowImage(p) {
    
    const w = Platform.OS !== 'web' ? windowWidth : 400
    const icon = Platform.OS === 'web' ? p.icon : p.route.params.image
    
    return (
        <View style={{alignItems: 'center', justifyContent: 'center', alignContent: 'center', flex: 1}}>
            <Image style={{height: w, width: w}} source={icon}/>
        </View>
        
    )
}


export function CustomModal(props) {

    const [objectInnerComponent, setObjectInnerComponent] = useState(null);
    
    return (
        <Modal 
            animationType="fade" 
            transparent={true} 
            visible={props.modalVisible} 
            onRequestClose={() => {
                //Alert.alert("Modal has been closed.");
                props.onClose();
            }}>
            <View style={{flex: 1,  
                backgroundColor: DARK_GRAY_TRANSPARENT, alignItems: "center", justifyContent: "center"}}>
                <View style={container.modal_view}>
                    <TouchableOpacity onPress={() => props.onClose()} style={{alignItems: 'flex-end', marginBottom: 15}}>
                        <CustomIcon name={'close'} size={25} color={BLACK} />
                    </TouchableOpacity> 
                    <View>
                        {props.innerComponet}
                    </View>
                    { props.has_ok && <View>
                        <TextButton type={BUTTON_FLAT} onPress={() => props.onOk(objectInnerComponent) } title={"OK"}/>
                    </View> }
                </View>
            </View>
      </Modal>
    )
}


const chooseStyle = (type, isDisabled = false) => {
    if (!isDisabled) {
        switch (type) {
            case BUTTON_PRIMARY:
                return {
                    "p": container.primay_button, 
                    "t": [getFont(18, WHITE, DEFAULT_FONT)], 
                    "i": {"size":22, "color": WHITE}
                }
            case BUTTON_PRIMARY_RED:
                return {
                        "p": container.primay_button_red, 
                        "t": [getFont(18, WHITE, DEFAULT_FONT)], 
                        "i": {"size":22, "color": WHITE}
                }
            case BUTTON_SECONDARY:
                return {
                    "p": container.secondary_button, 
                    "t": [getFont(18, GREEN, DEFAULT_FONT)], 
                    "i": {"size":22, "color": GREEN}
                }
            case BUTTON_TAB_ACTIVE:
                return {
                    "p": container.tab_active_button, 
                    "t": [getFont(22, GREEN, DEFAULT_FONT), {marginVertical: 10}],
                    "i": {"size":22, "color": GREEN}
                }
            case BUTTON_TAB_NOT_ACTIVE:
                return {
                    "p": container.tab_not_active_button, 
                    "t": [getFont(22, DARK_GRAY, DEFAULT_FONT), {marginVertical: 10}],
                    "i": {"size":22, "color": DARK_GRAY}
                }
            case BUTTON_FLAT:
                return {
                    "p": container.flat_button, 
                    "t": [getFont(18, GREEN, DEFAULT_FONT)],
                    "i": {"size":22, "color": GREEN}
                }
            case BUTTON_FLAT_GRAY:
                return {
                    "p": container.flat_button, 
                    "t": [getFont(18, DARK_GRAY, DEFAULT_FONT)],
                    "i": {"size":22, "color": DARK_GRAY}
                }
            case BUTTON_FLAT_LINK:
                return {
                    "p": container.flat_button, 
                    "t": [getFont(16, LINK_COLOR, DEFAULT_FONT)],
                    "i": {"size":22, "color": LINK_COLOR}
                }
            default:
                 return {
                     "p": container.flat_button, 
                     "t": [getFont(18, WHITE, DEFAULT_FONT)],
                     "i": {"size":22, "color": GREEN}
                 }
        }
    } else {
        if (type === BUTTON_FLAT_GRAY || type === BUTTON_FLAT || type === BUTTON_FLAT_LINK) {
            return {
                "p": container.flat_button, 
                "t": [getFont(18, DARK_GRAY, DEFAULT_FONT)],
                "i": {"size":22, "color": DARK_GRAY}
            }
        }
        return {"p": container.disable_button, "t": [getFont(18, WHITE, DEFAULT_FONT)]}
    }
    

    
}

export function TextButton(props) {


    const s = chooseStyle(props.type, props.disabled)
    return (
        <TouchableOpacity 
            onPress={props.onPress} 
            disabled={props.disabled} style={[s.p, props.extra_style]}>
            { props.isLoading ? 
                <ActivityIndicator size="small" style={{height: 30}} color={s.i.color} />  : 
                <Text style={[[...s.t], {justifyContent: 'center', textAlign: 'center'}]}>{props.title}</Text>
            }
        </TouchableOpacity>
    )
}

export function ChoiceTextButton(props) {    
    const size = 30
    const sizeInner = size*0.70
    
    return (
        <TouchableOpacity 
            onPress={props.onPress} 
            disabled={props.disabled} 
            style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{borderRadius: size/2, width: size, justifyContent: 'center', margin: 5, alignItems: 'center',
                height: size, borderColor: GREEN, borderWidth: 1, backgroundColor: CLEAR}}>
                { props.type === BUTTON_PRIMARY && 
                    <View style={{borderRadius: sizeInner/2, width: sizeInner, height: sizeInner, backgroundColor: GREEN}} />}
            </View>
            <Text>{props.title}</Text>
        </TouchableOpacity>
    )
}


export function YouTubeTextButton(props) {
    const s = chooseStyle(props.type, props.disabled)
    const channelId = props.youtubeID
    return (
        <TouchableOpacity 
            onPress={() => {
                
                    Linking.canOpenURL('vnd.youtube://channel/' + channelId).then(supported => {
                    if (Platform.OS === 'web' || supported === false) {
                        return Linking.openURL('https://www.youtube.com/watch?v=' + channelId);    
                    } else {
                        return Linking.openURL('vnd.youtube://' + channelId);    
                    }

                    }).catch( e => {
                
                        return Linking.openURL('https://www.youtube.com/watch?v=' + channelId);
                    });
            }}
            disabled={props.disabled} style={[s.p, props.extra_style]}>
            { props.isLoading ? 
                <ActivityIndicator size="small" style={{height: 30}} color={s.i.color} />  : 
                <Text style={[[...s.t], {justifyContent: 'center', textAlign: 'center', height: 30}]}>{props.title}</Text>
            }
        </TouchableOpacity>
    )
}

export const YouTubeIcon = (props) => {
    const channelId = props.youtubeID


    const openYoutube = (channelId) => {
        Linking.canOpenURL('vnd.youtube://channel/' + channelId).then(supported => {
            if (Platform.OS === 'web' || supported === false) {
                return Linking.openURL('https://www.youtube.com/watch?v=' + channelId);    
            } else {
                return Linking.openURL('vnd.youtube://' + channelId);    
            }
            }).catch( e => {
                return Linking.openURL('https://www.youtube.com/watch?v=' + channelId);
            });
    }


    return (
        <TouchableOpacity onPress={() => {
            if (props.askOpen) {
                createStandardAlert("Esci dall'app","vuoi aprire youtube?", () => openYoutube(channelId))
            } else {
                openYoutube(channelId)
            }
        }}>
            <CustomIcon name={"logo-youtube"} size={22} color={YOUTUBERED} />
        </TouchableOpacity>
    )
}


export function TextIconButton(props) {
    const s = chooseStyle(props.type, props.disabled)
    return (
        <TouchableOpacity onPress={props.onPress} 
            disabled={props.disabled} style={[s.p, props.extra_style, { flexDirection:'row', alignItems:'center' }]}>
            { props.isLoading ? 
                <ActivityIndicator size="small" style={{height: 30}} color={s.i.color} /> : 
                <>
                    <CustomIcon name={props.icon} style={{marginRight: 8}} size={s.i.size} color={s.i.color} />
                    <Text style={[[...s.t], {justifyContent: 'center', textAlign: 'center'}]}>
                        {props.title}
                    </Text>
                </>
            }
        </TouchableOpacity>)
}

export function IconButton(props) {
    const s = chooseStyle(props.type, props.disabled)
    return (
        <TouchableOpacity onPress={props.onPress} 
            disabled={props.disabled} style={[s.p, props.extra_style, { flexDirection:'row', alignItems:'center' }]}>
            { props.isLoading ? 
                <ActivityIndicator size="small" style={{height: 30}} color={s.i.color} /> : 
                <>
                    <CustomIcon name={props.icon} style={{marginRight: 8}} size={s.i.size} color={s.i.color} />
                </>
            }
        </TouchableOpacity>)
}


export const CustomTextInputWithLabel = React.forwardRef((p, ref) => (
    <View style={[ container.text_input, {flexDirection:'row', alignItems:'center', marginBottom: SPACE_BOTTOM} ]}>
        <Text style={[TEXT_B, {marginRight: 5}]}>{p.textLabel}</Text>
        <CustomInput is_secure={false} {...p} ref={ref}/>
    </View>
 ))

 export const PwdTextInput = React.forwardRef((p, ref) => {
    return (
        <View style={[ container.text_input, {flexDirection:'row', alignItems:'center', marginBottom: 8} ]}>
            <TouchableOpacity onPress={() => p.onPressShowPwd()}>
                <CustomIcon name={p.showPwd ? "eye-outline" : "eye-off-outline"} size={20} color={DARK_GRAY} />
            </TouchableOpacity>
            <CustomInput is_secure={true} {...p} ref={ref}/>
        </View>
    )}
)


export const CustomTextInput = React.forwardRef((p, ref) => (
    <View style={[ container.text_input, {flexDirection:'row', alignItems:'center', marginBottom: SPACE_BOTTOM} ]}>
        <CustomInput is_secure={false} {...p} ref={ref}/>
    </View>
))

export function LogoDCM() {
    return (
      <Image
        style={{ height: 25, width: 150 }}
        source={require('../../images/dcm_white_navigation.png')}
      />
    );
  }

export function ORView(p) {
    return <View style={{flexDirection:'row', paddingVertical: 20}}>
        <View style={[container.line, {flex:1}]}/>
        <Text style={[MEDIUM_B, { marginHorizontal: 5}]}>{p.orText}</Text> 
        <View style={[container.line, {flex:1}]}/>
    </View>
}

export function LineView(p) {
    return <View style={[container.line, {flex:1}]}/>
}


export const CustomTab = (p) => {
    return (
        <>
            <View>
                { p.main }
            </View>
        </>
    )
}



export const Badge = (p) => {
    return (<View style={{backgroundColor: p.backgroundColor, paddingHorizontal: 15, paddingVertical: 3, borderRadius: 8, marginRight: 5}}>
        <Text style={getFont(14, WHITE, DEFAULT_FONT)}>{p.name}</Text>
    </View>)
}


export const RowResult = (props) => {
    const f = props.size_result_font || getFont(35, DARK_GRAY, DEFAULT_FONT)
    return (
        <View style={{ flex: 1, padding: 8}}>
            <Text style={{...f, textAlign: 'center'}}>{props.label_right}</Text>
            <Text style={[getFont(12, DARK_GRAY), {
                textAlign: 'center'}]}>
                {props.label_left}
            </Text>
        </View>
    )
}


export const ResultCheck = () => {
    const size = 130
    return (
        <View style={{
            borderRadius: size/2, width: size, height: size, 
            alignItems: 'center', justifyContent: 'center'}}>
            <Image  
                source={require('../../images/check_animated.gif')} 
                style={[{ width: 200, height: 200 }]}/>
        </View>
    )
}

export function EmptyResult(p) {
    return (
        <View style={{padding: Platform.OS === 'web' ? 0 : DEFAULT_PAD, alignItems: 'center'}}>
            <View style={{ marginRight: 12}}>
                <Image  
                    source={require('../../images/no_result_animated.gif')} 
                    style={[{ width: 200, height: 200 }]}/>
            </View>
            <Text style={TITLE_B}>{p.message}</Text>
        </View>
    )
}



