import { View, Text } from 'react-native'
import React, { useState, useRef }  from "react";
import { CustomTextInput, TextButton } from "../../General/Other";
import { MEDIUM_B, BUTTON_PRIMARY  } from '../../../style'

export default function UpdateValue(p){
    
    const [textW, setTextW] = useState(p.data)
    var textInputWidth = useRef(null)
    
    return (
        <View>
            <Text style={MEDIUM_B}>
                {'Aggiorna il valore '}
            </Text>
            <CustomTextInput 
                ref={(ref) => textInputWidth.current = ref} 
                accept="float"
                keyboardType="numbers-and-punctuation" 
                returnKeyType='done'
                style={{padding: 5, ...MEDIUM_B}} 
                onChangeText={(t) => {
                    setTextW(t)
                }} 
                text={String(textW)}
                placeholder={'Inserisci il valore'}/>
            <TextButton 
                type={BUTTON_PRIMARY}
                onPress={() => p.onClickAction(textW) }
                title={"Update".toUpperCase()}/>
        </View>
    )
}